import React from 'react'
import { login, tally } from './links'

const babyAssoNavigationFR = {
  navigationButtons: [login, tally],
  items: [
    {
      text: 'Votre besoin',
      boxes: [
        {
          children: [
            {
              text: 'Gérer mon asso de A à Z',
              icon: 'common/icon/multicolor/members',
              href: '/tarifs/offre-serenite/',
            },
            {
              text: 'Simplifier ma comptabilité',
              icon: 'common/icon/multicolor/accounting',
              href: '/tarifs/offre-comptabilite/',
            },
          ],
        },
        {
          children: [
            {
              text: "Gratuit : collecter de l'argent",
              icon: 'common/icon/multicolor/donations',
              href: '/gratuit/',
            },
            {
              text: 'Structurer mon réseau',
              icon: 'common/icon/multicolor/network',
              href: '/reseaux/',
            },
          ],
        },
      ],
      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            Nouveau : récupérer de l’argent avec le lien de paiement
          </>
        ),
        href: '/fonctionnalites/lien-de-paiement/',
      },
      width: 600,
    },
    {
      text: 'Fonctionnalités',
      boxes: [
        {
          children: [
            {
              text: 'Compte pro',
              icon: 'common/icon/multicolor/membership',
              href: '#assurance',
            },

            {
              text: 'Assurance',
              icon: 'common/icon/multicolor/members',
              href: '/creer-association/assurance/',
            },
          ],
        },
      ],

      width: 200,
    },
    { text: 'Tarifs', href: '#tarif' },

    {
      text: 'Ressources',
      href: '/ressources/',
      boxes: [
        {
          children: [
            {
              text: 'Pourquoi et comment créer votre asso',
              href: '/blog/creer-une-association/',
            },
            {
              text: 'Guides gratuits',
              href: '/ressources/guides-associations/',
            },
            {
              text: 'Articles et conseils',
              href: '/blog/',
            },
          ],
        },
      ],
      footer: {
        text: <>💌 Newsletter </>,
        href: '/ressources/newsletter/',
      },
      width: 330,
    },
  ],
}

export default babyAssoNavigationFR
