import React, { FC } from 'react'
import {
  Wrapper,
  StyledLink,
  FrontMatterBlock,
  Title,
  Meta,
  Thumbnail,
  ReadingTime,
  CardPostFooter,
  Svg,
} from './styled'
import { AuthorWrap, AuthorAvatar, AuthorName, TagWrap, Tag } from '../..'
import btoa from 'btoa'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { ICardPost } from './type'

const CardPost: FC<ICardPost> = ({
  blogPath,
  width,
  post: {
    slug,
    feature_image: { url: imageUrl },
    body,
    meta: { title },
    topics,
    author,
  },
  obfuscated,
  translations,
}) => {
  const link = `${blogPath}/${slug}/`
  let result = 0
  if (body.length > 0) {
    result = Math.ceil(body.length / 3700)
  }
  return (
    <Wrapper width={width}>
      <StyledLink href={obfuscated ? btoa(link) : link}>
        <LazyLoadComponent delayTime={1500}>
          <Thumbnail image={imageUrl} />
        </LazyLoadComponent>
        <FrontMatterBlock>
          <TagWrap>
            {topics.map(({ name }, i) => (
              <Tag key={i}>{name}</Tag>
            ))}
          </TagWrap>
          <Title>{title}</Title>
          <CardPostFooter>
            <Meta>
              <Svg
                src="components/blog/icons/blog-reading-time-dark"
                alt="Reading time icon"
                width="20px"
              />
              <ReadingTime>
                {result} {translations.readTimeMinutes}
              </ReadingTime>
            </Meta>
            {author && (
              <AuthorWrap flip>
                <LazyLoadComponent delayTime={1500}>
                  <AuthorAvatar src={author.avatar} alt={author.AuthorName} />
                </LazyLoadComponent>
                <AuthorName color="#838383">{author.full_name}</AuthorName>
              </AuthorWrap>
            )}
          </CardPostFooter>
        </FrontMatterBlock>
      </StyledLink>
    </Wrapper>
  )
}

export default CardPost
