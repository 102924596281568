import React, { FC } from 'react'
import { SmallTitle, Space, Text } from '.'
import styled from 'styled-components'
import { ITextBox } from './type'

/**
 * Style
 */
const TextBoxStyled = styled.div<{ $hoverAnimation?: boolean }>`
  transition: opacity linear 0.3s;
  ${({ $hoverAnimation }) => $hoverAnimation && `opacity: .5`};

  &:hover {
    opacity: 1;
  }
`

/**
 * Component
 */
const TextBox: FC<ITextBox> = ({ datas, hoverAnimation = false }) => (
  <>
    {datas.map(data => (
      <TextBoxStyled $hoverAnimation={hoverAnimation} key={data.title}>
        <SmallTitle font="roboto" align="left" color="middleGrey">
          {data.title}
        </SmallTitle>
        <Space size="tiny" />
        <Text align="left">{data.text}</Text>
        <Space size="medium" />
      </TextBoxStyled>
    ))}
  </>
)

export default TextBox
