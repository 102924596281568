import React, { FC } from 'react'
import styled from 'styled-components'
import { EBreakpoint } from 'src/styles/type'

const PostMainStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: ${EBreakpoint.SMALL_MOBILE}) {
    flex: auto;
    max-width: none;
    width: 100%;
  }
`
const PostMain: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <PostMainStyled>{children}</PostMainStyled>
}

export default PostMain
