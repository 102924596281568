import React, { FC } from 'react'
import styled from 'styled-components'
import { EBreakpoint, EFontFamily } from 'src/styles/type'

export const BlogPageTitleStyled = styled.h1`
  font-family: ${EFontFamily.ROBOTO};
  padding: 10px 0px;
  font-size: 36px;
  line-height: 48px;
  font-weight: 900;
  color: #9b9b9b;
  width: 100%;
  padding-right: 15px;
  @media screen and (max-width: ${EBreakpoint.MOBILE}) {
    padding-right: 0;
  }
`
const BlogPageTitle: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <BlogPageTitleStyled>{children}</BlogPageTitleStyled>
}

export default BlogPageTitle
