import React from 'react'
import {
  SmallTitle,
  MiddleTitle,
  Svg,
  ButtonLink,
  Text,
  Box,
  Section,
  UserInterface,
  Space,
} from '../'
import styled from 'styled-components'

const AutomatedSectionTitle = styled(props => <MiddleTitle {...props} />)`
  margin: 0 auto 51px;
`
const AutomatedSectionSvg = styled(props => <Svg {...props} />)`
  max-width: 590px;
  margin-left: auto;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    margin: auto;
  }
`
const AutomatedSectionTextTitle = styled(props => <SmallTitle {...props} />)`
  margin-bottom: 6px;
  text-align: left !important;
`

const AutomatedSection = ({
  title,
  datas,
  svg = 'components/section/automated-gears-fr-fr',
  button: { href = '', text = '', target = null },
}) => (
  <Section background={{ color: 'blue' }}>
    <Box>
      <AutomatedSectionTitle color="white">{title}</AutomatedSectionTitle>
    </Box>
    <Box width={6 / 12}>
      <AutomatedSectionSvg src={svg} />
      <Space size="medium" className="hidden-desktop" />
    </Box>
    <Box width={6 / 12}>
      {datas.map(data => (
        <div key={data.title}>
          <AutomatedSectionTextTitle color="white">
            {data.title}
          </AutomatedSectionTextTitle>
          <Text color="white" border>
            {data.text}
          </Text>
          <Space size="medium" />
        </div>
      ))}
    </Box>
    <Box>
      <Space size="small" className="hidden-mobile" />
      <ButtonLink align="center" theme="white" href={href} target={target}>
        {text}
      </ButtonLink>
    </Box>
  </Section>
)

export default AutomatedSection
