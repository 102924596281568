import React, { FC } from 'react'
import { UserInterface, Svg, Link } from '..'
import styled from 'styled-components'
import classNames from 'classnames'

/**
 * Style
 */
const ButtonStyled = styled(props => <Link {...props} />)`
  font-weight: ${UserInterface.fonts.weight.bold};
  font-family: ${UserInterface.fonts.family.roboto};
  font-size: 0.824rem;

  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.235rem 0.706rem;
  transition: all 0.2s ease;
  color: ${UserInterface.colors.extraDarkGrey};
  cursor: pointer;
  height: 1.529rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 1.647rem;
    background: ${UserInterface.colors.pastelBlue};
    width: 1.529rem;
    height: 1.529rem;
    transition: all 0.3s ease;
  }
  &:hover::before {
    width: 100%;
    opacity: 1;
  }
  &:active {
    transform: scale(0.96);
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    font-size: 1rem;
  }
`
const ButtonTextChildren = styled.div`
  position: relative;
  vertical-align: middle;
  display: inline-block;
  line-height: 1rem;
  font-size: 1rem;
  &:first-letter {
    text-transform: uppercase;
  }
`
const ButtonTextArrow = styled(props => <Svg {...props} />)`
  margin-left: 0.5rem;
  position: relative;

  svg {
    vertical-align: middle;
    display: inline-block;
  }
`
export interface ButtonTextProps {
  className?: string
  href: string
  children: string
  target: string
}

/**
 * Component
 */
const ButtonText: FC<ButtonTextProps> = ({
  className,
  href,
  children = 'en savoir plus',
  target,
}) => (
  <ButtonStyled
    className={classNames('button', 'button--type-text', className)}
    href={href}
    isStyled={false}
    target={target}
  >
    <ButtonTextChildren>{children}</ButtonTextChildren>
    <ButtonTextArrow
      width="0.765rem"
      color="extraDarkGrey"
      src="common/icon/unicolor/small-arrow"
    />
  </ButtonStyled>
)

ButtonText.displayName = 'ButtonText' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default ButtonText
