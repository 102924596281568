import React from 'react'
import styled from 'styled-components'
import {
  BigTitle,
  Box,
  ButtonLink,
  Flex,
  AdaptiveImage,
  UserInterface,
  Section,
  Subtitle,
  Space,
} from '../'
import { Helmet } from 'react-helmet'

/**
 * Style
 */
const HeaderStyled = styled(({ backgroundColor, ...rest }) => (
  <Section background={{ color: backgroundColor }} {...rest} />
))`
  padding: 3rem 0;
`
const ImageStyled = styled(props => <AdaptiveImage {...props} />)`
  img {
    object-fit: contain;
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    height: 200px;
    img {
      width: auto;
    }
  }
`

/**
 * Component
 */
const HeaderSmall = ({
  className,
  yado,
  title,
  subTitle,
  buttons,
  videoId,
  translations,
  backgroundColor = 'blue',
}) => {
  return (
    <>
      {yado && (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={`${process.env.GATSBY_CDN_HOST}/${yado.src}-mobile.webp`}
            media="(max-width: 500px)"
          />
          <link
            rel="preload"
            as="image"
            href={`${process.env.GATSBY_CDN_HOST}/${yado.src}.webp`}
            media="(min-width: 500px)"
          />
        </Helmet>
      )}

      <HeaderStyled
        className={`${className} header`}
        videoId={videoId}
        translations={translations}
        backgroundColor={backgroundColor}
      >
        <Flex alignItems="center">
          <Box width={yado ? 3 / 4 : 1}>
            <BigTitle align="left" tag="h1">
              {title}
            </BigTitle>
            {subTitle && (
              <Subtitle color="white" align="left" tag="div">
                {subTitle}
              </Subtitle>
            )}
            {buttons && (
              <>
                <Space />
                <Section background="transparent" paddingY="0">
                  {buttons.map((button, index) => (
                    <ButtonLink
                      {...(index > 0 && { margin: '0 0 0 1rem' })}
                      key={index}
                      href={button.href}
                      theme={button.theme}
                      target={button.target}
                    >
                      {button.text}
                    </ButtonLink>
                  ))}
                </Section>
              </>
            )}

            <Space size="big" />
            <Space size="big" />
          </Box>
          {yado && (
            <Box width={1 / 4}>
              <Space className="hidden-desktop" />
              <ImageStyled
                src={yado.src}
                alt={yado.alt}
                maxDpi={1}
                height="20.5rem"
                visibleByDefault
              />
              <Space size="big" className="hidden-mobile" />
            </Box>
          )}
        </Flex>
      </HeaderStyled>
    </>
  )
}

export default HeaderSmall
