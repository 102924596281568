import { Sticky, StickyContainer } from 'react-sticky'

import React from 'react'
import ReactMarkdown from 'react-markdown'
import {
  BlogGrid,
  BlogMain,
  BlogPageTitle,
  BlogSection,
  CardPost,
  CardPostFeatured,
  FavPostsAside,
  FreeTrial,
  HeaderShadow,
  ListCtaBlock,
  Pager,
  Space,
  Layout,
  TagsFilter,
} from '../../index'

import { getTranslations } from '../../../utils/context'

export const getCtaCode = hubspotCta =>
  `
  <div style='display:flex;width:100%;justify-content:center;'>
    <span class="hs-cta-wrapper" id="hs-cta-wrapper-${hubspotCta.uuid}">
      <span class="hs-cta-node hs-cta-${hubspotCta.uuid}" id="hs-cta-${hubspotCta.uuid}">
        <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
        <a href="https://cta-redirect.hubspot.com/cta/redirect/${hubspotCta.code}/${hubspotCta.uuid}" target="_blank">
          <img loading="lazy" alt="cta-image" class="hs-cta-img" id="hs-cta-img-${hubspotCta.uuid}" style="border-width:0;" src="https://no-cache.hubspot.com/cta/default/${hubspotCta.code}/${hubspotCta.uuid}.png"/>
        </a>
      </span>
      <script type="text/javascript"> window?.hbspt.cta.load(${hubspotCta.code}, '${hubspotCta.uuid}', {}); </script>
    </span>
  </div>
`

const Posts = ({
  data: {
    allHubspotPost: { edges: posts },
    lastpost: { edges: lastPost },
    favoritesposts: { edges: favoritesPosts },
  },
  pageContext: {
    blogPath,
    hrefLangs,
    meta,
    hubspotNewsletter,
    hubspotCta,
    favoritesSlugsObfuscation,
    fixedCategories,
    previousPagePath,
    nextPagePath,
    pageNumber,
    numberOfPages,
    pathPrefix,
    favoritesSlugs,
    ...context
  },
  components: { Newsletter },
  title,
}) => {
  const translations = getTranslations()

  return (
    <Layout
      hreflangs={hrefLangs}
      headerBackgroundColor="white"
      header="BLOG"
      homePath={blogPath}
      isBlog={true}
      meta={meta}
    >
      <style>{`body { background: white !important; }`}</style>
      <HeaderShadow />
      <BlogSection paddingBottom="0">
        {lastPost?.length && (
          <CardPostFeatured blogPath={blogPath} post={lastPost[0].node} />
        )}
        <TagsFilter
          blogPath={blogPath}
          topics={fixedCategories}
          translations={translations}
        />
      </BlogSection>
      <BlogSection paddingBottom="75px">
        <BlogPageTitle>{title || context.title}</BlogPageTitle>
        <BlogGrid>
          <BlogMain>
            {posts &&
              posts.map((post, i) => {
                post.node.slug = `${post.node.slug}`
                return (
                  <CardPost
                    key={i}
                    post={post.node}
                    blogPath={blogPath}
                    translations={translations}
                  />
                )
              })}
            <Pager
              pageContext={{
                previousPagePath,
                nextPagePath,
                pageNumber,
                numberOfPages,
                pathPrefix,
              }}
              translations={translations}
            />
          </BlogMain>
          <ListCtaBlock>
            <Newsletter
              hubspotId={hubspotNewsletter.id}
              hubspotCode={hubspotNewsletter.code}
              buttonHref={hubspotNewsletter.href}
              title={hubspotNewsletter.title}
              subtitle={hubspotNewsletter.subtitle}
              translations={translations}
            />
            {favoritesPosts && (
              <FavPostsAside
                blogPath={blogPath}
                favoritesPosts={favoritesPosts}
                favoritesSlugs={favoritesSlugs}
                favoritesSlugsObfuscation={favoritesSlugsObfuscation}
                favoritesTitle={translations.favorites}
              />
            )}
            <StickyContainer
              style={{ height: 'calc(100% - 1135px)', width: '96%' }}
            >
              <Sticky topOffset={-60}>
                {({ style, isSticky }) => (
                  <div
                    style={{ ...style, marginTop: isSticky ? '100px' : '0px' }}
                  >
                    <FreeTrial cardtitle={translations.post.sidebarCtaTitle}>
                      <ReactMarkdown
                        source={getCtaCode(hubspotCta)}
                        escapeHtml={false}
                      />
                    </FreeTrial>
                  </div>
                )}
              </Sticky>
            </StickyContainer>
          </ListCtaBlock>
        </BlogGrid>
        <Newsletter
          hubspotId={hubspotNewsletter.id}
          hubspotCode={hubspotNewsletter.code}
          mobile
          buttonHref={hubspotNewsletter.href}
          title={hubspotNewsletter.title}
          subtitle={hubspotNewsletter.subtitle}
          translations={translations}
        />
      </BlogSection>
      <Space big />
      <Space big />
      <Space big />
    </Layout>
  )
}
export default Posts
