import React from 'react'

export const navigationButtons = {
  ASSOCONNECT: [
    {
      text: 'Se connecter',
      href: 'https://app.assoconnect.com/login',
      theme: 'link-yellow',
      id: 'login',
    },
    {
      text: 'Commencer gratuitement',
      href: 'https://app.assoconnect.com/sign-up/first?subscription=SERENITE',
      target: '_self',
      theme: 'link-blue',
      id: 'try',
    },
  ],
  SPRINGLY: [
    {
      text: 'Login',
      href: 'https://app.springly.org/login',
      theme: 'link-white',
    },
    {
      text: 'Try Springly',
      href: 'https://app.springly.org/sign-up/first/?lang=en_US',
    },
  ],
}

export const blogNavigationButtons = {
  ASSOCONNECT: [
    {
      text: 'Découvrir AssoConnect',
      href: 'https://www.assoconnect.com/',
      target: '_self',
      theme: 'link-white',
    },
    {
      text: 'Essai gratuit',
      href: 'https://app.assoconnect.com/sign-up/first?subscription=SERENITE',
      target: '_self',
    },
  ],
  SPRINGLY: [
    {
      text: 'Discover Springly',
      href: 'https://www.springly.org/en-us/',
      target: '_self',
      theme: 'link-white',
    },
    {
      text: 'Try Springly',
      href: 'https://app.springly.org/sign-up/first/?lang=en_US',
    },
  ],
}

export const headerItems = {
  ASSOCONNECT: [
    {
      text: 'Fonctionnalités',
      boxes: [
        {
          children: [
            {
              text: 'Comptabilité',
              icon: 'common/icon/multicolor/accounting',
              href: '/fonctionnalites/logiciel-comptabilite-association/',
            },
            {
              text: 'Gestion des membres',
              icon: 'common/icon/multicolor/members',
              href: '/fonctionnalites/gestion-membres-association/',
            },
          ],
        },
        {
          children: [
            {
              text: 'Paiement en ligne',
              icon: 'common/icon/multicolor/refund',
              href: '/fonctionnalites/solution-paiement-en-ligne-association/',
            },
            {
              text: 'Communication',
              icon: 'common/icon/multicolor/communication',
              href: '/fonctionnalites/communication-association/',
            },
          ],
        },
      ],
      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            Nouveau : Collectez vos paiements gratuitement
          </>
        ),
        href: '/gratuit/',
      },
      width: 450,
    },
    { text: 'Offres & Tarifs', href: '/tarifs/' },
    {
      text: 'Pour qui ?',

      boxes: [
        {
          children: [
            {
              text: 'Associations',
              icon: 'common/icon/multicolor/charity',
              href: '/associations/',
            },
            {
              text: 'Réseaux',
              icon: 'common/icon/multicolor/network',
              href: '/reseaux/',
            },
          ],
        },
        {
          children: [
            {
              text: 'Clubs',
              icon: 'common/icon/multicolor/sport',
              href: '/associations/logiciel-club-de-sport/',
            },
            {
              text: 'Fédérations',
              icon: 'common/icon/multicolor/connection',
              href: '/reseaux/federations/',
            },
          ],
        },
      ],
      width: 350,
    },

    {
      text: 'Ressources',
      href: '/ressources/',
      boxes: [
        {
          title: 'À propos d’AssoConnect',
          children: [
            {
              text: 'Cas clients',
              arrow: true,
              href: '/blog/tag/témoignages/',
            },
            {
              text: 'Nouveautés',
              arrow: true,
              href: 'https://product.assoconnect.com/page/410379',
            },
            {
              text: 'Se former sur AssoConnect',
              arrow: true,
              href: 'https://product.assoconnect.com/page/2147492-centre-de-formation-d-assoconnect',
            },
            {
              text: 'Services personnalisés',
              arrow: true,
              href: '/services/',
            },
          ],
        },
        {
          title: 'Autres ressources',
          children: [
            {
              text: 'Blog',
              arrow: true,
              href: '/blog/',
            },
            {
              text: 'Livres blancs',
              arrow: true,
              href: '/ressources/guides-associations/',
            },
            {
              text: 'Webinaires AssoConnect',
              arrow: true,
              href: '/ressources/formations-en-ligne/',
            },
            {
              text: 'Ressources sport',
              arrow: true,
              href: '/ressources/associations-sportives/',
            },
          ],
        },
      ],
      width: 530,
    },
  ],
  SPRINGLY: [
    {
      text: 'What we do',
      href: '/en-us/nonprofit-software/',
      boxes: [
        {
          children: [
            {
              text: 'CRM',
              icon: 'common/icon/multicolor/members',
              href: '/en-us/features/crm-nonprofit/',
            },
            {
              text: 'Membership',
              icon: 'common/icon/multicolor/membership',
              href: '/en-us/features/nonprofit-membership-software/',
            },
            {
              text: 'Accounting',
              icon: 'common/icon/multicolor/accounting',
              href: '/en-us/features/nonprofit-accounting/',
            },
          ],
        },
        {
          children: [
            {
              text: 'Website & Communication',
              icon: 'common/icon/multicolor/communication',
              href: '/en-us/features/nonprofit-communication-tools/',
            },
            {
              text: 'Events',
              icon: 'common/icon/multicolor/ticketing',
              href: '/en-us/features/nonprofit-events/',
            },
            {
              text: 'Discover all of our features',
              icon: 'common/icon/multicolor/network',
              arrow: true,
              href: '/en-us/nonprofit-software/',
            },
          ],
        },
      ],

      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            Collect online payments for free ✨
          </>
        ),
        href: '/en-us/pricing/liberty/',
      },
      width: 500,
    },
    {
      text: 'Who we serve',
      href: '/en-us/software-for-nonprofits-by-sector/',
      boxes: [
        {
          children: [
            {
              text: '501(c)(3) organizations',
              icon: 'common/icon/multicolor/members',
              href: '/en-us/nonprofit-software/',
            },
            {
              text: 'Clubs',
              icon: 'common/icon/multicolor/sport',
              href: '/en-us/nonprofit/club-management-software/',
            },
            {
              text: 'Associations',
              icon: 'common/icon/multicolor/political',
              href: '/en-us/nonprofit/association-management-software/',
            },
            {
              text: 'Churches & Religious Organizations',
              icon: 'common/icon/multicolor/religious',
              href: '/en-us/nonprofit/church-management-software/',
            },
            {
              text: 'Networks',
              icon: 'common/icon/multicolor/connection',
              href: '/en-us/networks/',
            },
            {
              text: 'Students, Colleges & Universities',
              icon: 'common/icon/multicolor/student',
              href: '/en-us/nonprofit/software-for-colleges-and-universities/',
            },
            {
              text: 'Professional Organizations',
              icon: 'common/icon/multicolor/pro',
              href: '/en-us/nonprofit/professional-association-software/',
            },
          ],
        },
      ],
      width: 340,
    },
    {
      text: 'Pricing',
      href: '/en-us/pricing/',
    },
    {
      text: 'Resources',
      href: '/en-us/resources/',
      boxes: [
        {
          title: 'About Springly',
          children: [
            {
              text: 'Our tutorials',
              arrow: true,
              href: '/en-us/demo/',
            },
            {
              text: 'Board Presentations',
              arrow: true,
              href: '/en-us/resources/board-documents/',
            },
            {
              text: 'Compare Your Software',
              arrow: true,
              href: '/en-us/resources/compare',
            },
            {
              text: 'Partnerships',
              arrow: true,
              href: '/en-us/partnerships/techsoup',
            },
            {
              text: 'Product updates',
              arrow: true,
              href: 'https://product-evolution.springly.org/page/2020738-product-updates',
            },
            {
              text: 'Our services',
              arrow: true,
              href: '/en-us/services',
            },
          ],
        },
        {
          title: 'Other resources',
          children: [
            {
              text: 'Blog',
              arrow: true,
              href: '/en-us/blog/',
              nofollow: false,
            },
            {
              text: 'Newsletter',
              arrow: true,
              href: '/en-us/resources/newsletter/',
            },
            {
              text: 'eBooks',
              arrow: true,
              href: '/en-us/resources/ebooks/',
            },
            {
              text: 'Nonprofit Management Circle',
              arrow: true,
              href: '/en-us/nonprofit-management-circle/',
            },
            {
              text: 'Nonprofit Tips',
              arrow: true,
              href: '/en-us/resources/nonprofit-tips/',
            },
          ],
        },
      ],
      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            NEW EBOOK: Nonprofit Networks
          </>
        ),
        href: '/en-us/resources/ebooks/networks/',
      },
      width: 520,
    },
  ],
}

export const footerColumns = {
  ASSOCONNECT: [
    {
      title: 'Recherches fréquentes',
      items: [
        {
          text: 'Logiciel club de Football',
          href: '/associations/logiciel-club-de-sport/football/',
        },
        {
          text: 'Logiciel club de Basket',
          href: '/associations/logiciel-club-de-sport/basket/',
        },
        {
          text: 'Logiciel club de Danse',
          href: '/associations/logiciel-club-de-sport/danse/',
        },
        {
          text: 'Logiciel club de Judo',
          href: '/associations/logiciel-club-de-sport/judo/',
        },
        {
          text: 'Logiciel club de Tennis',
          href: '/associations/logiciel-club-de-sport/tennis/',
        },
      ],
    },
    {
      title: 'Solution associations',
      items: [
        {
          text: 'Solution de paiement en ligne pour association',
          href: '/fonctionnalites/solution-paiement-en-ligne-association/',
        },
        {
          text: 'Intranet pour association',
          href: '/fonctionnalites/intranet-association/',
        },
        {
          text: 'ERP pour association',
          href: '/fonctionnalites/erp-association/',
        },
        {
          text: 'CRM pour association',
          href: '/fonctionnalites/crm-association/',
        },
        {
          text: 'Logiciel Association Gratuit',
          href: '/logiciel-association-gratuit/',
        },
        {
          text: 'Logiciel Comptabilité Gratuit',
          href: '/logiciel-association-gratuit/comptabilite/',
        },
        {
          text: 'Site Internet Gratuit',
          href: '/logiciel-association-gratuit/site/',
        },
      ],
    },
    {
      title: 'Ressources',
      items: [
        {
          text: 'Blog AssoConnect FR',
          href: '/blog/',
          nofollow: null,
        },
        {
          text: 'Blog AssoConnect US',
          href: 'https://www.springly.org/en-us/blog/',
          nofollow: false,
        },
        {
          text: 'Formations pour association',
          href: '/ressources/formations-en-ligne/',
        },
        {
          text: 'Guides gratuits pour associations',
          href: '/ressources/guides-associations/',
        },
        { text: 'OpenAsso', href: 'https://www.openasso.org/' },
        {
          text: "Newsletter d'AssoConnect",
          href: '/ressources/newsletter/',
        },
        {
          text: 'Se former sur AssoConnect',
          href: 'https://product.assoconnect.com/page/2147492-centre-de-formation-d-assoconnect',
        },
      ],
    },
    {
      title: 'Entreprise',
      items: [
        {
          text: 'Qui sommes-nous ?',
          href: '/qui-sommes-nous/',
        },

        {
          text: 'La Charte AssoConnect',
          href: ' https://www.assoconnect.com/charte-assoconnect/',
        },
        { text: "L'équipe AssoConnect", href: '/equipe/' },
        { text: 'AssoConnect Recrute', href: '/jobs/' },
        { text: 'Page Presse', href: '/presse/' },
        {
          text: 'Contacter AssoConnect',
          href: '/contact/',
        },
        { text: 'Nous aider', href: '/nous-aider/' },
        { text: "Partenaires d'AssoConnect", href: '/partenaires/' },
        {
          text: 'Partenariats avec AssoConnect',
          href: '/partenariats/',
        },
        { text: 'Foire Aux Questions', href: '/faq/' },
      ],
    },
  ],
  SPRINGLY: [
    {
      title: 'Product overview',
      items: [
        {
          text: 'What We Do',
          icon: 'common/icon/multicolor/refund-us',
          href: '/en-us/nonprofit-software/',
        },
        {
          text: 'Who We Serve',
          icon: 'common/icon/multicolor/deal',
          href: '/en-us/software-for-nonprofits-by-sector/',
        },
        {
          text: 'Pricing',
          icon: 'common/icon/multicolor/donations-us',
          href: '/en-us/pricing/',
        },
        {
          text: 'Demo',
          icon: 'common/icon/multicolor/online-software',
          href: '/en-us/demo',
        },
        {
          text: 'Product Updates',
          icon: 'common/icon/multicolor/online-software',
          href: 'https://product-evolution.springly.org/page/2020738-product-updates',
        },
        {
          text: 'Compare',
          icon: 'common/icon/multicolor/online-software',
          href: '/en-us/resources/compare/',
        },
        {
          text: 'Help Center',
          icon: 'common/icon/multicolor/online-software',
          href: 'https://help.springly.org/hc/en-us/',
        },
      ],
    },
    {
      title: 'Nonprofit Resources',
      items: [
        {
          text: 'FAQ',
          href: '/en-us/resources/faq/',
          nofollow: false,
        },
        {
          text: 'Blog',
          href: '/en-us/blog/',
          nofollow: false,
        },
        {
          text: 'Newsletter',
          href: '/en-us/resources/newsletter/',
        },
        {
          text: 'eBooks',
          href: '/en-us/resources/ebooks/',
        },
        {
          text: 'Nonprofit Management Circle',
          href: '/en-us/nonprofit-management-circle/',
        },

        {
          text: 'Giving Tuesday Guide',
          href: '/en-us/resources/giving-tuesday/',
        },
      ],
    },
    {
      title: 'Company Overview',
      items: [
        {
          text: 'About Us',
          href: '/en-us/about-us/',
        },
        {
          text: 'Our Commitment',
          href: '/en-us/our-commitment/',
        },
        {
          text: 'Team',
          href: '/en-us/team/',
        },
        {
          text: 'Privacy Policy',
          href: '/en-us/info-cookies/',
        },
        {
          text: 'Contact Us',
          href: 'https://help.springly.org/hc/en-us/requests/new',
        },
      ],
    },
  ],
}

export const footerSocials = {
  ASSOCONNECT: [
    {
      href: 'https://www.facebook.com/AssoConnect1/',
      icon: 'common/icon/unicolor/facebook',
    },
    {
      href: 'https://www.linkedin.com/company/assoconnect',
      icon: 'common/icon/unicolor/linkedin',
    },
    {
      href: 'https://www.instagram.com/assoconnect/',
      icon: 'common/icon/unicolor/instagram',
    },
    {
      href: 'https://www.youtube.com/channel/UCcRcK51ypc33_sPxe_GpjKQ',
      icon: 'common/icon/unicolor/youtube',
    },
  ],
  SPRINGLY: [
    {
      href: 'https://www.facebook.com/springly.org',
      icon: 'common/icon/unicolor/facebook',
      nofollow: true,
    },
    {
      href: 'https://www.linkedin.com/company/springlyorg/about/',
      icon: 'common/icon/unicolor/linkedin',
      nofollow: true,
    },
    {
      href: 'https://www.youtube.com/channel/UCMX0mw1gXLAbH-SSc6xLqkA',
      icon: 'common/icon/unicolor/youtube',
      nofollow: true,
    },
  ],
}

export const defaultFooterCta = {
  ASSOCONNECT: {
    demo: {
      link: '/video-presentation-assoconnect/',
      text: (
        <>
          Pas encore,
          <br className="hidden-desktop" /> je découvre AssoConnect
        </>
      ),
    },
    trial: {
      link: 'https://app.assoconnect.com/sign-up/first?subscription=SERENITE',
      target: '_self',
      text: <>Je démarre un essai gratuit</>,
    },
  },
  SPRINGLY: {
    demo: {
      link: '/en-us/demo/',
      text: (
        <>
          Book
          <br className="hidden-desktop" /> a demo
        </>
      ),
    },
    trial: {
      link: 'https://app.springly.org/sign-up/first/?lang=en_US',
      text: <>Start a free trial</>,
    },
  },
}

export const footerCopyrights = {
  ASSOCONNECT: [
    { href: '/mentions-legales/', text: 'Mentions légales' },
    { href: '/cguv/', text: 'CGUV' },
    { href: '/cgvps/', text: 'CGVPS' },
    { href: '/infos/cookie/', text: 'Politique de Confidentialité' },
  ],
  SPRINGLY: [
    { href: '/en-us/legal-notices/', text: 'Legal notices' },
    { href: '/en-us/gtcus/', text: 'Terms and Conditions' },
  ],
}

export const snippets = {
  ASSOCONNECT: `[{
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://www.assoconnect.com/",
    "name": "AssoConnect",
    "logo": "${process.env.GATSBY_CDN_HOST}/common/logo/assoconnect.png",
    "sameAs": [
      "https://www.facebook.com/AssoConnect1/",
      "https://twitter.com/AssoConnect",
      "https://www.linkedin.com/company/assoconnect",
      "https://www.instagram.com/assoconnect/",
      "https://www.youtube.com/channel/UCcRcK51ypc33_sPxe_GpjKQ"        
    ]            
  }, {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "AssoConnect",
    "applicationCategory": "BusinessApplication",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": 4.8,
      "ratingCount": 533
    },
    "offers": {
      "@type": "Offer",
      "price": 24.00,
      "priceCurrency": "EUR"
    }
  }]
`,
  SPRINGLY: `[{
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://www.springly.org/en-us/",
    "name": "Springly",
    "logo": "${process.env.GATSBY_CDN_HOST}/common/logo/springly.png",
    "sameAs": [
      "https://www.facebook.com/springly.org/",
      "https://www.linkedin.com/company/springlyorg/",
      "https://www.youtube.com/channel/UCPl9fuabM-ALF6DcPIeEubQ"        
    ]            
  }, {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Springly",
    "applicationCategory": "BusinessApplication",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": 4.8,
      "ratingCount": 533
    },
    "offers": {
      "@type": "Offer",
      "price": 45.00,
      "priceCurrency": "USD"
    }
  }]`,
}
