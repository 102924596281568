import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import classNames from 'classnames/bind'
import { useInViewport } from 'react-in-viewport'

/**
 * Style
 */

const VideoPadding = styled.div`
  padding: 0;
  margin-top: 20px;
  position: relative;
  ${props => props.$headerHomeVideo && 'height: 0px; width: 0px;'};
`

const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
`
const VideoStyled = styled.div`
  .wistia-click-to-play {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
  }
`

/**
 * Component
 */
const Video = ({
  videoId = '',
  autoplay,
  backgroundPlayer,
  wistiaParameters = [],
  scriptLoadedFromClick = false,
  headerHomeVideo = false,
}) => {
  const myRef = useRef()
  const { inViewport } = useInViewport(myRef)

  useEffect(() => {
    const existingScript = document.getElementById('wistia')

    // Load script if video isInViewPort and not from HeaderHome (loaded onClick)
    if (!scriptLoadedFromClick && inViewport && !existingScript) {
      const wistiaScript = document.createElement('script')
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      wistiaScript.id = 'wistia'
      document.body.appendChild(wistiaScript)
    }
  }, [inViewport, scriptLoadedFromClick])

  if (backgroundPlayer) {
    wistiaParameters.push([
      'autoPlay=true',
      'endVideoBehavior=loop',
      'volumeControl=false',
      'smallPlayButton=false',
      'settingsControl=false',
      'playSuspendedOffScreen=true',
      'playsinline=false',
      'playbar=false',
      'playButton=false',
      'muted=true',
      'fullscreenButton=false',
    ])
  } else if (autoplay) {
    wistiaParameters.push(['autoPlay=true'])
  }

  return (
    <VideoPadding
      className="wistia_responsive_padding"
      $headerHomeVideo={headerHomeVideo}
    >
      <VideoWrapper ref={myRef} className="wistia_responsive_wrapper">
        <VideoStyled
          className={classNames(
            'wistia_embed',
            `wistia_async_${videoId}`,
            'videoFoam=true',
            ...wistiaParameters
          )}
        >
          &nbsp;
        </VideoStyled>
      </VideoWrapper>
    </VideoPadding>
  )
}

export default Video
