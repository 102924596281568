import React, { createElement, FC } from 'react'
import { UserInterface } from '..'
import styled, { css } from 'styled-components'
import classNames from 'classnames'
import { IText } from './type'

/**
 * Style
 */

const TextBorderCommon = css`
  padding-left: 17px;
  &::before {
    width: 3px;
    height: 100%;
    border-radius: 3px;
    content: '';
    position: absolute;
    left: 0;
  }
`
const TextBorderWhite = css`
  ${TextBorderCommon}
  &::before {
    background-color: ${UserInterface.colors.white};
  }
`
const TextBorderBlue = css`
  ${TextBorderCommon}
  &::before {
    background-color: ${UserInterface.colors.blue};
  }
`

const TextStyled = styled(({ tag, children, ...rest }: IText) =>
  createElement(tag ?? 'span', rest, children)
)`
  position: relative;
  font-family: ${UserInterface.fonts.family.roboto};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color && UserInterface.colors[color]};
  ${({ color, border }) => {
    if (!border) return
    return color === 'white' ? TextBorderWhite : TextBorderBlue
  }}

  &.text--size-small {
    font-size: 0.824rem;
    font-weight: ${UserInterface.fonts.weight.light};
  }
  &.text--size-big {
    font-size: 1rem;
    font-weight: ${({ light }) =>
      UserInterface.fonts.weight[light ? 'light' : 'normal']};
  }
  &.text--size-extraBig {
    font-size: 2.4rem;
    font-weight: 700;
  }
`

/**
 * Component
 */
const Text: FC<IText> = ({
  align = 'left',
  children,
  className,
  color = 'middleGrey',
  size = 'big',
  tag = 'div',
  light = undefined,
  border = undefined,
  id,
}) => {
  /**
   * Output
   */
  return (
    <TextStyled
      id={id}
      tag={tag}
      align={align}
      color={color}
      light={light}
      border={border}
      className={classNames('text', `text--size-${size}`, className)}
    >
      {children}
    </TextStyled>
  )
}
Text.displayName = 'Text' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default Text
