import React from 'react'
import styled from 'styled-components'

import { EFontFamily } from 'src/styles/type'

export const WrapperBorderedScroller = styled.div`
  position: relative;
  display: block;
`

export const WrapperBordered = styled(({ ...props }) => <div {...props} />)`
  font-family: ${EFontFamily.ROBOTO};
  width: 96%;
  background-color: #fff;
  color: #fff;
  padding: 20px;
  border: ${props => (props.grey ? '3px solid #939393' : '3px solid #316bf2')};
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 40px;
  &.sticky {
    width: 100%;
  }
  img {
    max-width: 100% !important;
    height: auto;
  }
`

export const TitleBordered = styled(({ ...props }) => <div {...props} />)`
  position: absolute;
  top: -30px;
  background: #fff;
  padding: 5px;
  color: ${props => (props.grey ? '#939393' : '#316bf2')};
  font-weight: 900;
  line-height: 37px;
  font-size: 18px;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    width: 20px;
  }
`
