export const defaultHeaderButtons = {
  ASSOCONNECT: [
    {
      text: 'Commencer gratuitement',
      href: 'https://app.assoconnect.com/sign-up/first?subscription=SERENITE',
      theme: 'header-blue',
    },
    {
      text: 'Voir les offres',
      href: '/tarifs',
      theme: 'yellow',
    },
  ],
  SPRINGLY: [
    {
      text: 'Learn more',
      href: 'https://app.springly.org/sign-up/first/?lang=en_US',
      theme: 'header-blue',
    },
    {
      text: 'See offers',
      href: '/en-us/pricing/',
      theme: 'yellow',
    },
  ],
}
