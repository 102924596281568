import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { UserInterface, AdaptiveImage, Svg } from '../../'
import 'slick-carousel/slick/slick.css'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

const SliderCardArrowStyled = styled(({ ...props }) => <div {...props} />)`
  cursor: pointer;
  ${({ navigation }) =>
    navigation === 'previous' && `transform: rotate( -180deg)`};
  .svg--color-primary {
    transition: 0.3s;
  }
  &:hover {
    .svg--color-primary {
      fill: ${UserInterface.colors.middleGrey};
    }
  }
`

/**
 * Style
 */
const SliderCardArrow = ({ onClick, navigation }) => {
  return (
    <SliderCardArrowStyled navigation={navigation} onClick={onClick}>
      <Svg src="common/icon/unicolor/arrow" width="2.5rem" color="lightGrey" />
    </SliderCardArrowStyled>
  )
}
const SliderCardDots = styled.ul`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`
const SliderCardDot = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${UserInterface.colors.lightGrey};
  display: block;
  margin: 10px;
  transition: 0.3s;

  .slick-active & {
    background-color: ${UserInterface.colors.blue};
  }
`
const SliderCardWrapper = styled.div`
  width: 100%;

  .slick-slider {
    display: flex;
    align-items: center;

    @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
      flex-direction: column-reverse;
      .slick-list {
        width: 60vw;
      }
    }
    @media screen and (max-width: ${UserInterface.breakpoints.smallMobile}) {
      flex-direction: column-reverse;
      .slick-list {
        width: 90vw;
      }
    }
  }
`
const SliderCardYado = styled(props => <AdaptiveImage {...props} />)`
  max-width: 100%;
  position: absolute;
  bottom: -1.765rem;
  right: calc(100% - 3rem);
  height: 20.5rem;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`

/**
 * Component
 */
const SliderCard = ({
  children,
  yado,
  slidesToShow = 3,
  changeInMobile = true,
  visibleByDefault = false,
}) => {
  const settings = {
    infinite: true,
    slidesToShow: slidesToShow,
    focusOnSelect: true,
    centerPadding: '0',
    centerMode: true,
    nextArrow: <SliderCardArrow />,
    prevArrow: <SliderCardArrow navigation="previous" />,
    responsive: [
      {
        breakpoint: UserInterface.breakpoints.mobile,
        settings: {
          slidesToShow: changeInMobile ? 1 : 3,
          arrows: false,
          dots: true,
          appendDots: dots => <SliderCardDots>{dots}</SliderCardDots>,
          customPaging: () => {
            return (
              <div>
                <SliderCardDot />
              </div>
            )
          },
        },
      },
    ],
  }
  return (
    <LazyLoadComponent threshold={400} visibleByDefault={visibleByDefault}>
      <SliderCardWrapper changeInMobile={changeInMobile}>
        <Slider {...settings}>{children}</Slider>
        {yado && (
          <SliderCardYado src={`common/yado/${yado.image}`} alt={yado.alt} />
        )}
      </SliderCardWrapper>
    </LazyLoadComponent>
  )
}

export default SliderCard
