import React, { useEffect, useState } from 'react'
import { IMenu } from './type'
import NavigationButtons from './NavigationButtons'
import { Link, UserInterface } from '..'
import NavigationToggle from './NavigationToggle'
import classNames from 'classnames'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import * as Style from './style'
import useFixedNavbar from './useFixedNavbar'
import { getNavigationLogoSrc } from './utils'
import breakpoints from 'src/styles/breakpoints'
import { normalizeString } from 'src/utils/string'

const Menu: React.FC<IMenu> = ({
  menuItems,
  cta,
  homePath = '/',
  headerBackgroundColor = 'transparent',
}) => {
  const [navigationIsFixed, setNavigationIsFixed] = useState(false)
  const [navigationIsMobile, setNavigationIsMobile] = useState(false)
  const [navigationMobileIsOpened, setNavigationMobileIsOpened] =
    useState(false)

  const [currentSubMenuId, setCurrentSubMenuId] = useState<string | undefined>(
    undefined
  )
  const [activePanel, setActivePanel] = useState<string | null>(null)
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false)

  // detect if the navigation should be fixed on resize
  const handleResize = () => {
    if (window?.matchMedia(`(min-width: ${breakpoints.mobile})`).matches) {
      setNavigationIsMobile(false)
    } else {
      setNavigationIsMobile(true)
    }
  }

  useEffect(() => {
    handleResize()

    window?.addEventListener('resize', handleResize)
    return () => {
      window?.removeEventListener('resize', handleResize)
    }
  }, [])

  useFixedNavbar(
    navigationIsFixed,
    setNavigationIsFixed,
    navigationMobileIsOpened,
    UserInterface.breakpoints
  )

  // Toggle mobile navigation
  const handleNavigationToggleBurger = () => {
    setNavigationMobileIsOpened(!navigationMobileIsOpened)
  }

  // Handle submenu open (on hover or click)
  const handleMouseEnter = (panelId: string) => {
    setActivePanel(panelId)
    setIsPanelVisible(true)
  }

  const handleSubMenuClick = (menuItemTitle: string) => {
    if (menuItemTitle === currentSubMenuId) {
      setCurrentSubMenuId(undefined)
    } else {
      setCurrentSubMenuId(menuItemTitle)
    }
  }

  return (
    <>
      <Style.NavigationMargin />
      <Style.NavigationStyled
        headerBackgroundColor={headerBackgroundColor}
        navigationIsFixed={navigationIsFixed}
        navigationMobileIsOpened={navigationMobileIsOpened}
      >
        <NavigationToggle
          navigationIsFixed={navigationIsFixed}
          navigationToggleBurgerFunction={handleNavigationToggleBurger}
          navigationMobileIsOpened={navigationMobileIsOpened}
          navigationType="white"
          homePath={homePath}
        />
        <Style.NavigationInner
          navigationIsFixed={navigationIsFixed}
          className="navigation__inner"
        >
          <Style.MenuSubContainer>
            <Style.NavigationLogoWrapper px="0" width="125px">
              <Link aria-label="Home" href={homePath} isStyled={false}>
                <Style.NavigationLogo
                  className="navigation__logo"
                  navigationIsFixed={navigationIsFixed}
                  color="white"
                  width="auto"
                  height={navigationIsFixed ? '30px' : '65px'}
                  src={getNavigationLogoSrc(navigationIsFixed)}
                  visibleByDefault
                />
              </Link>
            </Style.NavigationLogoWrapper>
            <Style.MainMenu onMouseLeave={() => setCurrentSubMenuId(undefined)}>
              {menuItems.map((menuItem, mainMenuIndex) => {
                const normalizedTitle = normalizeString(menuItem.title)
                return (
                  <Style.MenuItem
                    className={classNames('Menu__MenuItem')}
                    key={mainMenuIndex}
                  >
                    <Style.MenuLink
                      className={classNames(
                        'Menu__MenuLink',
                        normalizedTitle === currentSubMenuId ? 'active' : ''
                      )}
                      href={menuItem.href ?? '#'}
                      onMouseEnter={() =>
                        !navigationIsMobile &&
                        setCurrentSubMenuId(normalizedTitle)
                      }
                      onClick={() => handleSubMenuClick(normalizedTitle)}
                    >
                      {menuItem.title}
                      {menuItem?.firstLevelItems && (
                        <Style.NavigationChildArrow
                          className={classNames(
                            'navigation__arrow',
                            currentSubMenuId === normalizedTitle ? 'active' : ''
                          )}
                          src="common/icon/unicolor/arrow"
                          color="darkGrey"
                          width="12px"
                        />
                      )}
                    </Style.MenuLink>
                    {menuItem?.firstLevelItems && (
                      <Style.SubMenu
                        isVisible={normalizedTitle === currentSubMenuId}
                        isPanelVisible={isPanelVisible}
                        onMouseLeave={() =>
                          !navigationIsMobile && setIsPanelVisible(false)
                        }
                      >
                        <Style.leftPanelContainer
                          isPanelVisible={isPanelVisible}
                          isVisible={normalizedTitle === currentSubMenuId}
                          isOpen={isPanelVisible}
                        >
                          {menuItem.firstLevelItems.map(
                            (firstLevelItem, index) => (
                              <Style.SubMenuItem
                                className={classNames(
                                  'Menu__MenuItem__subMenuItem'
                                )}
                                key={index}
                                onMouseEnter={() =>
                                  !navigationIsMobile &&
                                  firstLevelItem.secondLevelitems &&
                                  handleMouseEnter(firstLevelItem.title)
                                }
                              >
                                <a href={firstLevelItem.href ?? '#'}>
                                  <Style.FirstLevelItemContainer>
                                    {firstLevelItem.icon && (
                                      <Style.NavigationChildIcon
                                        className={classNames(
                                          'navigation__icon'
                                        )}
                                        color="darkGrey"
                                        src={firstLevelItem.icon}
                                        width="24px"
                                      />
                                    )}
                                    {firstLevelItem.title}
                                    {firstLevelItem.badge && (
                                      <Style.Badge>
                                        {firstLevelItem.badge}
                                      </Style.Badge>
                                    )}
                                  </Style.FirstLevelItemContainer>
                                </a>
                              </Style.SubMenuItem>
                            )
                          )}
                        </Style.leftPanelContainer>
                        <Style.RightPanelContainer isOpen={isPanelVisible}>
                          {menuItem.firstLevelItems.map(
                            firstLevelItem =>
                              firstLevelItem.secondLevelitems && (
                                <Style.Panel
                                  key={firstLevelItem.title}
                                  isVisible={
                                    activePanel === firstLevelItem.title &&
                                    isPanelVisible
                                  }
                                >
                                  {firstLevelItem.secondLevelitems?.title && (
                                    <Style.PanelHeader>
                                      {firstLevelItem.secondLevelitems?.title}
                                    </Style.PanelHeader>
                                  )}
                                  <Style.PanelList>
                                    {firstLevelItem.secondLevelitems?.items?.map(
                                      (
                                        secondLevelItem,
                                        secondLevelItemIndex
                                      ) => (
                                        <Style.SubMenuItem
                                          key={secondLevelItemIndex}
                                          isExtra={
                                            secondLevelItem.type === 'extra'
                                          }
                                        >
                                          <a href={secondLevelItem.href}>
                                            {secondLevelItem.text}
                                            {secondLevelItem.type ===
                                              'extra' && (
                                              <LazyLoadComponent>
                                                <Style.Arrow
                                                  className="animation--effect-wobble"
                                                  src="common/icon/unicolor/small-arrow"
                                                  color="turquoise"
                                                  width="16px"
                                                />
                                              </LazyLoadComponent>
                                            )}
                                          </a>
                                        </Style.SubMenuItem>
                                      )
                                    )}
                                  </Style.PanelList>
                                </Style.Panel>
                              )
                          )}
                        </Style.RightPanelContainer>
                      </Style.SubMenu>
                    )}
                  </Style.MenuItem>
                )
              })}
            </Style.MainMenu>
          </Style.MenuSubContainer>
          <NavigationButtons cta={cta} />
        </Style.NavigationInner>
      </Style.NavigationStyled>
    </>
  )
}

export default Menu
