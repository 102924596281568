import { Fonts } from './type'

const fonts: Fonts = {
  family: {
    // TO DO: Delete this file after all components are using Typescript
    poppins: 'Poppins, sans-serif',
    roboto: 'Roboto, sans-serif',
    caveat: 'Caveat, sans-serif',
  },
  weight: {
    light: 200,
    normal: 400,
    medium: 600,
    bold: 700,
  },
}

export default fonts
