import React, { FC } from 'react'
import { ITeam } from './type'
import { Box, AnimationSwapImage, Text, SmallTitle } from '.'

const Team: FC<ITeam> = ({ team }) => (
  <>
    {team.map(({ image, name, job, maxDpi = 2 }) => (
      <Box width={1 / 3} key={name}>
        <AnimationSwapImage
          image={`pages/equipe/${image}`}
          imageHover={`pages/equipe/${image}-hover`}
          maxDpi={maxDpi}
        />
        <SmallTitle align="center">{name}</SmallTitle>
        <Text align="center">{job}</Text>
      </Box>
    ))}
  </>
)

export default Team
