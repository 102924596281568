import React, { FC } from 'react'
import { CardPost } from '../..'
import styled from 'styled-components'
import { EBreakpoint, EFontFamily } from 'src/styles/type'
import { ICardPostRecommended } from './type'

const Section = styled.section`
  background-color: rgba(232, 232, 232, 0.4);
  padding: 50px 20px 85px 20px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  justify-content: space-between !important;
  flex-wrap: nowrap;
  margin: 30px auto;
  max-width: 1140px;
  @media screen and (max-width: ${EBreakpoint.MOBILE}) {
    margin: 30px 5%;
  }
  @media screen and (max-width: ${EBreakpoint.SMALL_MOBILE}) {
    flex-direction: column;
    margin: auto;
  }
`
const BlogPageTitleLast = styled.div`
  font-family: ${EFontFamily.ROBOTO};
  margin-bottom: 30px;
  color: #4a4a4a;
  font-size: 36px;
  font-weight: 900;
  line-height: 48px;
  text-align: center;
`

const CardPostRecommended: FC<ICardPostRecommended> = ({
  blogPath,
  recommendedPosts,
  recommendedSlugs,
  recommendedSlugsObfuscation,
  translations,
}) => {
  // Sort recommendedPosts
  recommendedPosts.sort(
    (a, b) =>
      recommendedSlugs.indexOf(a.node.slug) -
      recommendedSlugs.indexOf(b.node.slug)
  )
  return (
    <Section>
      <BlogPageTitleLast>{translations['recommendedTitle']}</BlogPageTitleLast>
      <Wrapper>
        {recommendedPosts.map(({ node: post }, i) => (
          <CardPost
            blogPath={blogPath}
            key={i}
            width={1 / 3}
            post={post}
            obfuscated={recommendedSlugsObfuscation.includes(post.slug)}
            translations={translations}
          />
        ))}
      </Wrapper>
    </Section>
  )
}

export default CardPostRecommended
