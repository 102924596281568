import React, { FC } from 'react'
import styled from 'styled-components'

import { IDots } from './type'
import { UserInterface } from '../../..'

/**
 * Style
 */
const DotContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: inherit;

  @media (min-width: 750px) {
    display: none;
  }
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${UserInterface.colors['lightGrey']};
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: ${UserInterface.colors['blue']};
  }
`

/**
 * Cards Multi Component
 */
const Dots: FC<IDots> = ({ items, displayItemId, currentItemId }) => {
  return (
    <DotContainer>
      {items.map((_, index: number) => (
        <Dot
          key={index}
          className={`${index === currentItemId ? 'active' : ''}`}
          onClick={() => displayItemId(index)}
        />
      ))}
    </DotContainer>
  )
}

export default Dots
