/**
 * Prevent double slash errors in url
 * @param str
 * @returns {string}
 */
export const removeDoubleSlashFromUrl = str => {
  const hasProtocol = str.indexOf('https://') >= 0
  const cleanStr = hasProtocol ? str.replace(/^https?:\/\//, '') : str
  return `${hasProtocol ? 'https://' : ''}${cleanStr.replace('//', '/')}`
}

export const normalizeString = string => {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase()
}
