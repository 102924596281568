import React, { FC } from 'react'
import styled from 'styled-components'
import { IAuthorWrap } from './type'
import { EFontFamily } from 'src/styles/type'

const AuthorWrapStyled = styled(props => <div {...props} />)`
  align-items: center !important;
  display: flex !important;
  flex-direction: ${props => (props.flip ? 'row-reverse' : 'row')};
  font-family: ${EFontFamily.ROBOTO};
`
const AuthorWrap: FC<IAuthorWrap> = ({ children, flip }) => {
  return <AuthorWrapStyled flip={flip}>{children}</AuthorWrapStyled>
}

export default AuthorWrap
