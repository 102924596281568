// import { createGlobalStyle, css } from 'styled-components'

import animations from './animations'
import breakpoints from './breakpoints'
import colors from './colors'
import fonts from './fonts'
import shadows from './shadows'
import GlobalStyle from './GlobalStyleComponent'

const styles = {
  animations,
  breakpoints,
  colors,
  fonts,
  GlobalStyle,
  shadows,
}

export default styles
