import React, { Children, createElement, FC } from 'react'
import styled from 'styled-components'
import { UserInterface, Link } from '../..'
import getDisplayName from 'react-display-name'
import classNames from 'classnames'
import { EBreakpoint, EColor, ETheme } from 'src/styles/type'
import { ICard } from './type'

/**
 * Style
 */
const CardStyled = styled(
  ({ children, tag, $minHeight, $align, $verticalAlign, ...rest }) =>
    createElement(tag, rest, children, $minHeight, $align, $verticalAlign)
)`
  min-height: ${({ $minHeight }) => $minHeight};
  height: calc(100% - 2.4rem);
  padding: 1.875rem;
  transition: all linear 0.3s;
  border-radius: 10px;
  text-align: ${({ $align }) => $align};
  ${props =>
    props.$verticalAlign &&
    `align-items: ${props.$verticalAlign}; 
    justify-content: ${(props: { $align: string }) => props.$align};`}
  box-shadow: ${({ theme }) =>
    UserInterface.shadows[theme === 'blue' ? 'slider' : 'sliderLight']};
  background: ${({ theme }) => EColor[theme as keyof typeof EColor]};
  margin: 1.2rem 2rem;
  display: ${({ $verticalAlign }) => ($verticalAlign ? ' flex' : 'block')};
  text-decoration: none !important;

  @media screen and (min-width: ${EBreakpoint.EXTRA_SMALL_MOBILE}) {
    margin: 1.2rem 4rem;
  }

  @media screen and (min-width: 750px) {
    margin: 1.2rem 5rem;
  }

  @media screen and (min-width: ${EBreakpoint.SMALL_MOBILE}) {
    margin: 1.2rem 7rem;
  }

  @media screen and (min-width: ${EBreakpoint.MOBILE}) {
    margin: 1.2rem 0;

    &.card--hover-animation {
      border: 1px solid ${EColor.LIGHT_GREY};
      box-shadow: none;

      &:hover {
        box-shadow: ${({ theme }) =>
          UserInterface.shadows[
            theme === ETheme.BLUE ? 'slider' : 'sliderLight'
          ]};
        border-color: transparent;
        .text {
          ${({ theme }) =>
            theme === ETheme.WHITE && `color: ${EColor.MIDDLE_GREY}`};
        }
        .svg--color-primary {
          ${({ theme }) =>
            theme === ETheme.WHITE && `fill: ${EColor.MIDDLE_GREY}`};
        }
      }
      .text {
        transition: color linear 0.3s;
      }
      svg * {
        transition: fill linear 0.3s;
      }
    }
  }
`

/**
 * Card Component
 *
 * verticalAlign : start - center - end
 */
const Card: FC<ICard> = ({
  align = 'center',
  verticalAlign = false,
  children,
  hoverAnimation,
  minHeight = 'auto',
  theme = 'white',
  className,
  href = null,
}) => (
  <CardStyled
    $align={align}
    $verticalAlign={verticalAlign}
    theme={theme}
    tag={href ? Link : 'div'}
    $minHeight={minHeight}
    className={classNames(className, 'card', {
      'card--hover-animation': hoverAnimation,
    })}
    href={href}
  >
    {theme === ETheme.BLUE
      ? Children.map(children, child => {
          if (React.isValidElement(child) && child && child.type) {
            switch (getDisplayName(child.type)) {
              case 'MiddleTitle':
              case 'SmallTitle':
              case 'Text':
              case 'BulletList':
              case 'Svg':
                return React.cloneElement(child as React.ReactElement, {
                  color: child.props.color || 'white',
                })
              case 'Image':
                return React.cloneElement(child as React.ReactElement, {
                  theme: 'blue',
                })

              default:
                return child
            }
          }
        })
      : Children.map(children, child => {
          if (React.isValidElement(child) && child && child.type) {
            switch (getDisplayName(child.type)) {
              case 'MiddleTitle':
              case 'SmallTitle':
              case 'Text':
              case 'Svg':
                return React.cloneElement(child as React.ReactElement, {
                  color: child.props.color || 'middleGrey',
                })

              default:
                return child
            }
          }
        })}
  </CardStyled>
)

export default Card
