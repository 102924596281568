import React, { FC } from 'react'
import styled from 'styled-components'
import { ButtonLink, UserInterface } from '..'
import { EHeaderCta, INavigationButtons } from './type'
import { getHeaderCta, getHeaderLogin } from './utils'
import Wisepop from './Wisepop'
/**
 * Style
 */
const NavigationButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  background-color: transparent;

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    flex-direction: row;

    & > div:first-child > a {
      margin-left: 0;
    }
    & > div:last-child > a {
      margin-right: 0;
    }
  }
`
const NavigationButtonsStyled = styled(props => <ButtonLink {...props} />)`
  margin: 8px 15px;

  & .button--size-small {
    font-weight: bold;
    padding: 0.7rem 1.1rem;
  }
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    width: max-content;
  }

  .button {
    font-size: 0.9rem;
    padding: 0.7rem 1.1rem !important;

    &.button--theme-link-yellow {
      background-color: transparent !important;
      border-color: ${UserInterface.colors.yellow} !important;

      &:hover {
        color: ${UserInterface.colors.yellowHover} !important;
        border-color: ${UserInterface.colors.yellowHover} !important;
      }
    }

    &.button--theme-line-white {
      color: ${UserInterface.colors.white} !important;
      border-color: ${UserInterface.colors.white} !important;
    }
    &.button--theme-link-white {
      color: ${UserInterface.colors.white} !important;
    }
  }

  /* Mobile mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    margin: 0 10px;
    display: inline-block;
    flex-direction: row;
  }
`

/**
 * Component
 */
const NavigationButtons: FC<INavigationButtons> = ({
  cta = EHeaderCta.APP_SUSCRIPTION,
}) => {
  const login = getHeaderLogin()
  const headerCta = getHeaderCta(cta)

  return (
    <>
      <NavigationButtonsWrapper>
        <Wisepop />
        {/* Login */}
        <div className="text-center">
          <NavigationButtonsStyled
            href={login.href}
            size="small"
            className="navigation__buttons"
            target="_self"
            theme="link-yellow"
          >
            <span>{login.title}</span>
          </NavigationButtonsStyled>
        </div>

        {/* CTA */}
        {headerCta && (
          <div className="text-center">
            <NavigationButtonsStyled
              href={headerCta.href}
              size="small"
              className="navigation__buttons"
              target="_self"
              theme="link-blue"
            >
              <span>{headerCta.title}</span>
            </NavigationButtonsStyled>
          </div>
        )}
      </NavigationButtonsWrapper>
    </>
  )
}
export default NavigationButtons
