import React, { FC } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'
import { UserInterface, Svg } from '../..'
import { IPager } from './type'

export const PagerWrap = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 30px auto;
  padding: 0;
`

export const ArrowBlue = styled(props => (
  <Svg src="components/blog/icons/blog-arrow-blue" width="15px" {...props} />
))`
  ${({ transform }) => `transform: ${transform};`}
  ${({ margin }) => `margin: ${margin};`}
  display: inline-flex;
  width: 13.04px;
  vertical-align: middle;
  > div > div {
    display: flex;
  }
`

export const ArrowWhite = styled(props => (
  <Svg src="components/blog/icons/blog-arrow-white" width="15px" {...props} />
))`
  ${({ transform }) => `transform: ${transform};`}
  ${({ margin }) => `margin: ${margin};`}
  display: none;
  width: 13.04px;
  vertical-align: middle;
  > div > div {
    display: flex;
  }
`

export const PagerLink = styled(({ ...rest }) => <GatsbyLink {...rest} />)`
  padding: 5px 0 !important;
  color: #316bf2;
  text-decoration: none;
  line-height: 38px;
  background-color: transparent;
  font-family: ${UserInterface.fonts.family.roboto};
  &.active {
    color: white !important;
  }
`

export const PagerNextPrev = styled.div`
  float: none;
  display: inline-block;
  margin: 5px;
  padding: 0 15px;
  font-family: ${UserInterface.fonts.family.roboto};

  &.active,
  &:hover {
    border-radius: 22.5px;
    background-color: #316bf2;
    color: #fff;
  }
  &:hover ${ArrowBlue} {
    display: none !important;
  }
  &:hover ${ArrowWhite} {
    display: inline-flex !important;
  }
  &:hover ${PagerLink} {
    color: #fff;
  }
`
export const PagerNum = styled(({ ...props }) => <div {...props} />)`
  width: 38px;
  height: 38px;
  text-align: center;
  display: inline-block;
  margin: 5px;
  &.active,
  &:hover {
    border-radius: 22.5px;
    background-color: #316bf2;
    color: #fff;
  }
  &.active,
  &:hover ${PagerLink} {
    color: #fff;
  }
`

const PagerCurrentPageMobile = styled.div`
  display: inline-block;
  color: #316bf2;
  font-family: ${UserInterface.fonts.family.roboto};
`

const PagerNumbersList = styled.div`
  display: inline-block;
`

const getPaginationArray = (pageNumber: number, numberOfPages: number) => {
  const paginationItemsNumber = 5

  // Display x first pages (x = paginationItemsNumber)
  if (
    numberOfPages <= paginationItemsNumber ||
    pageNumber + 1 <= Math.ceil(paginationItemsNumber / 2)
  ) {
    return Array.from(Array(paginationItemsNumber).keys())
  }

  // Display x last pages (x = paginationItemsNumber)
  if (pageNumber >= numberOfPages - Math.floor(paginationItemsNumber / 2) - 1) {
    return Array.from(Array(paginationItemsNumber).keys()).map(
      index => index + numberOfPages - paginationItemsNumber
    )
  }

  return Array.from(Array(paginationItemsNumber).keys()).map(
    index => index + pageNumber - Math.floor(paginationItemsNumber / 2)
  )
}

const getPagePath = (page: number, prefix: string) => {
  const path = [`/${prefix}`]
  if (page !== 0) {
    path.push(`page/${page + 1}/`)
  }

  return path.join('/')
}

const Pager: FC<IPager> = ({ pageContext, translations }) => {
  const {
    previousPagePath,
    nextPagePath,
    pageNumber,
    numberOfPages,
    pathPrefix,
  } = pageContext

  return (
    <PagerWrap>
      <PagerNextPrev>
        {previousPagePath && (
          <PagerLink to={`/${previousPagePath}/`} rel="prev">
            <ArrowBlue
              alt="arrow pager"
              transform="rotate(180deg)"
              margin="auto 10px auto auto"
            />
            <ArrowWhite
              alt="arrow pager"
              transform="rotate(180deg)"
              margin="auto 10px auto auto"
            />
            <span className="hidden-mobile">{translations.pager.previous}</span>
          </PagerLink>
        )}
      </PagerNextPrev>
      <PagerCurrentPageMobile className="hidden-desktop">
        {translations.pager.page} {pageNumber + 1} / {numberOfPages}
      </PagerCurrentPageMobile>
      <PagerNumbersList className="hidden-mobile">
        {getPaginationArray(pageNumber, numberOfPages).map(
          page =>
            page < numberOfPages && (
              <PagerLink
                key={page}
                to={getPagePath(page, pathPrefix)}
                className={pageNumber === page && 'active'}
              >
                <PagerNum className={pageNumber === page && 'active'}>
                  {page + 1}
                </PagerNum>
              </PagerLink>
            )
        )}
      </PagerNumbersList>
      <PagerNextPrev>
        {nextPagePath && pageNumber + 1 < numberOfPages && (
          <PagerLink to={`/${nextPagePath}/`} rel="next">
            <span className="hidden-mobile">{translations.pager.next} </span>
            <ArrowBlue alt="arrow pager" margin="auto auto auto 10px" />
            <ArrowWhite alt="arrow pager" margin="auto auto auto 10px" />
          </PagerLink>
        )}
      </PagerNextPrev>
    </PagerWrap>
  )
}

export default Pager
