import React from 'react'
import { Box, Flex } from '@rebass/grid'
import styled, { css } from 'styled-components'
import { UserInterface } from '..'
import Svg from '../Svg'

// Logo
export const NavigationLogoWrapper = styled(props => <Box {...props} />)`
  display: none;
  /* Mobile mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
  }
`
export const NavigationLogo = styled(props => <Svg {...props} />)`
  display: block;
  text-align: center;
  ${({ navigationIsFixed }) => navigationIsFixed && `margin: auto;`}
`

/**
 * Helpers css
 */
export const navigationCommonCss = css`
  > .navigation__arrow .svg--color-primary,
  .navigation__logo .svg--color-primary {
    fill: ${UserInterface.colors.blue};
  }
  .navigation__buttons {
    .button--theme-line-white {
      color: ${UserInterface.colors.middleGrey};
      border-color: ${UserInterface.colors.middleGrey};
    }
    .button--theme-link-white {
      color: ${UserInterface.colors.middleGrey};
    }
  }
  .navigation__inner {
    opacity: 1;
    color: ${UserInterface.colors.middleGrey};

    &:hover {
      color: ${UserInterface.colors.blue};

      .navigation__arrow.active .svg--color-primary {
        fill: ${UserInterface.colors.blue};
      }
    }
    > a {
      color: ${UserInterface.colors.middleGrey};

      &:hover {
        color: ${UserInterface.colors.blue};
      }
    }
    .navigation__arrow {
      opacity: 1;

      .svg--color-primary {
        fill: ${UserInterface.colors.darkGrey};
      }
    }
  }
  .phoneNumber svg path {
    fill: ${UserInterface.colors.darkGrey};
  }
`

export const navigationWhiteCss = css`
  background-color: ${UserInterface.colors.white};
  .button--theme-link-yellow {
    color: ${UserInterface.colors.middleGrey} !important;
  }
  ${navigationCommonCss}
  .wisp {
    svg path {
      stroke: black;
    }
  }
`

export const navigationTransparentCss = css`
  background-color: transparent;
  ${navigationCommonCss}
`

export const navigationClearBlueCss = css`
  background-color: ${UserInterface.colors.clearBlue};
  ${navigationCommonCss}
`

export const navigationBlueCss = css`
  > .navigation__arrow .svg--color-primary,
  .navigation__logo .svg--color-primary {
    fill: ${UserInterface.colors.white};
  }

  background-color: ${UserInterface.colors.blue};

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    .Menu__MenuLink {
      color: ${UserInterface.colors.white};
      opacity: 0.9;

      > .navigation__arrow .svg--color-primary {
        fill: ${UserInterface.colors.white} !important;
      }

      &.active {
        color: ${UserInterface.colors.white};
        opacity: 1;
      }
    }

    .navigation__buttons {
      .button--theme-link-yellow {
        color: ${UserInterface.colors.white} !important;

        &:hover {
          color: ${UserInterface.colors.yellowHover} !important;
        }
      }

      .button--theme-link-blue {
        background-color: ${UserInterface.colors.yellow};
        font-weight: ${UserInterface.fonts.weight.bold};

        &:hover {
          background-color: ${UserInterface.colors.yellowHover};
        }
      }
    }
  }
`

export const navigationFixedCss = css`
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    position: fixed;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.19);
    ${navigationWhiteCss};
  }
`
export const navigationInnerFixedCss = css`
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    && {
      height: 80px;
      margin-bottom: 0;
      align-items: center;

      .navigation__items {
        margin-bottom: 0px;
      }

      .wisp {
        padding-bottom: 0;
        padding-top: 0.3rem;
      }
    }
  }
`
export const NavigationMargin = styled.div`
  height: 80px;
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    height: 0;
  }
`

export const NavigationStyled = styled.nav<{
  headerBackgroundColor: string
  navigationIsFixed: boolean
  navigationMobileIsOpened: boolean
}>`
  transition: background-color ease-out 0.2s;
  background-color: ${UserInterface.colors.blue};
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding-left: 0;

  /* Color */
  ${({
    headerBackgroundColor,
    navigationIsFixed,
    navigationMobileIsOpened,
  }) => {
    if (headerBackgroundColor === 'white' && !navigationIsFixed) {
      return navigationWhiteCss
    }

    if (headerBackgroundColor === 'clearBlue' && !navigationIsFixed) {
      return navigationClearBlueCss
    }
    if (headerBackgroundColor === 'transparent' && !navigationIsFixed) {
      return navigationTransparentCss
    }
    if (
      headerBackgroundColor === 'blue' &&
      !navigationIsFixed &&
      !navigationMobileIsOpened
    ) {
      return navigationBlueCss
    }
  }};

  transition: all ease-out 0.2s;
  position: fixed;
  bottom: 0;
  ${({ navigationMobileIsOpened }) =>
    !navigationMobileIsOpened && 'left:-100%'};
  ${navigationWhiteCss}

  /* Scroll Fixed (only desktop mode) */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    ${({ navigationIsFixed }) => navigationIsFixed && navigationFixedCss};
    transition: none;
    position: relative;
    left: 0;
    bottom: auto;
    padding-left: 2.5rem;

    ${({
      headerBackgroundColor,
      navigationIsFixed,
      navigationMobileIsOpened,
    }) => {
      if (headerBackgroundColor === 'white' && !navigationIsFixed) {
        return navigationWhiteCss
      }

      if (headerBackgroundColor === 'clearBlue' && !navigationIsFixed) {
        return navigationClearBlueCss
      }
      if (headerBackgroundColor === 'transparent' && !navigationIsFixed) {
        return navigationTransparentCss
      }
      if (
        headerBackgroundColor === 'blue' &&
        !navigationIsFixed &&
        !navigationMobileIsOpened
      ) {
        return navigationBlueCss
      }
    }};
  }
`

export const NavigationInner = styled(props => <Flex {...props} />)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  /* Specific style for all features */
  .navigation__child--all-features {
    color: ${UserInterface.colors.middleGrey};
    font-weight: ${UserInterface.fonts.weight.bold};

    /* Desktop mode */
    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      border-top: 1px solid ${UserInterface.colors.extraLightGrey};
      text-align: center;
      padding-top: 12px;
      margin-top: 0;
    }
  }
  height: calc(100% - 130px);
  margin-top: 80px;
  overflow-y: scroll;

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    flex-direction: row;
    margin: auto;
    max-width: 1500px;
    overflow: visible !important;
    && {
      height: 100px;
      align-items: center;
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    height: 100%;
    margin-top: 0;
    overflow: none;
    overflow-y: hidden;
  }
  /* Scroll fixed (only desktop mode) */
  ${({ navigationIsFixed }) => navigationIsFixed && navigationInnerFixedCss};
`

export const MenuSubContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
`

export const MainMenu = styled.ul`
  font-family: 'Roboto', sans-serif;
  list-style-type: none;
  width: 100%;
  margin: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    flex-direction: row;
    padding-left: 50px;
    justify-content: end;
  }
`

export const MenuItem = styled.li`
  position: relative;
  width: auto;
  padding: 1rem 0rem;
  &:hover {
    border-radius: 8px;
  }

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    margin-left: 20px;
  }
`

export const MenuLink = styled.a`
  font-size: 0.9em;
  font-weight: 500;
  display: block;
  padding: 9px 20px;
  text-decoration: none;
  color: ${UserInterface.colors.middleGrey};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
    padding: 9px 13px;
    &.active {
      color: ${UserInterface.colors.blue};
    }
  }
`
export const NavigationChildArrow = styled(props => <Svg {...props} />)`
  display: flex;
  rotate: 90deg;
  transition: transform 0.4s;
  align-items: center;
  padding: 0;

  svg {
    height: 15px;
  }

  &.active {
    transform: rotate(-180deg);
  }

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-right: 0;
    svg {
      width: 10px;
    }
  }
`

export const SubMenu = styled.ul<{
  isVisible: boolean
  isPanelVisible: boolean
}>`
  font-weight: 500;
  font-size: 0.9em;
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  overflow: hidden;
  box-shadow: rgb(202, 202, 202) 0 1px 3px;

  ${({ isPanelVisible }) => isPanelVisible && 'overflow: visible;'};

  background-color: white;
  z-index: 1;
  width: auto;

  border-radius: ${({ isPanelVisible }) =>
    isPanelVisible ? '10px 0 0 10px' : '10px'};

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    position: absolute;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    height: auto;
    background-color: ${UserInterface.colors.white};
  }
`

export const leftPanelContainer = styled.div<{
  isVisible: boolean
  isPanelVisible: boolean
  isOpen: boolean
}>`
  width: 100%;
  background-color: ${UserInterface.colors.white};
  border-radius: 10px;

  &::after {
    content: '';
    position: absolute;
    z-index: 99999999;
    top: 0;
    left: 275px;
    bottom: 0;
    width: 1px;
    background: ${({ isOpen }) =>
      isOpen
        ? `linear-gradient(to bottom, transparent, ${UserInterface.colors.lightGrey} 20%, ${UserInterface.colors.lightGrey} 80%, transparent)`
        : 'none'};
    pointer-events: none;
  }

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    width: 275px;
  }
`

export const RightPanelContainer = styled.div<{ isOpen: boolean }>`
  display: block;
  color: ${UserInterface.colors.middleGrey};
  font-weight: 400;
  font-size: 0.9em;

  /* Transition for width, opacity, and padding */
  transition: width 0.2s ease, padding 0.2s ease, opacity 0.2s ease, height: 0.4s ease;

  /* Set width, padding, and opacity based on isOpen */
  height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  width: ${({ isOpen }) => (isOpen ? '250px' : '0')};
  padding: ${({ isOpen }) => (isOpen ? '10px 0' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')}; /* Hides content visually */


  overflow: hidden; /* Prevents content overflow when width is 0 */
  top: 0;
  z-index: 99999;
  border-radius: 0 10px 10px 0;
  background-color: ${UserInterface.colors.white};
`

export const SubMenuItem = styled.li<{ isExtra?: boolean }>`
  border-top: ${({ isExtra }) => (isExtra ? '1px solid #e6e6e6' : 'none')};
  a {
    font-size: 0.85em;
    display: block;
    padding: 20px 0 20px 20px;
    text-decoration: none;
    color: #333;
    background-color: ${UserInterface.colors.clearBlue};

    &:hover {
      background-color: ${UserInterface.colors.clearBlue};
    }

    /* Desktop mode */
    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      font-size: 0.9em;
      padding: 15px 0 15px 30px;
      background-color: ${UserInterface.colors.white};
      width: 100%;
      padding-right: 20px;
    }
  }

  &.Menu__MenuItem__subMenuItem:first-of-type {
    a {
      border-radius: 10px 0 0 0;
    }
  }
  &.Menu__MenuItem__subMenuItem:last-of-type {
    a {
      border-radius: 0 0 0 10px;
    }
  }

  .navigation__icon {
    margin-right: 10px;
    width: 26px;
    height: 26px;
  }
`

export const FirstLevelItemContainer = styled.div`
  display: flex;
  align-items: center;
`

export const NavigationChildIcon = styled(props => <Svg {...props} />)`
  vertical-align: middle;
  display: inline-block;
  margin-right: 20px;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    svg {
      width: 26px;
      height: 26px;
    }
  }
`

export const Badge = styled.div`
  background-color: ${UserInterface.colors.blue};
  color: ${UserInterface.colors.white};
  font-size: 0.8em;
  padding: 0.3rem 0.6rem;
  border-radius: 8px;
  margin-left: 10px;
  width: auto;
  text-transform: uppercase;
`

export const Panel = styled.div<{ isVisible: boolean }>`
  display: none;

  /* Mobile mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    z-index: 1;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  }
`

export const PanelHeader = styled.div`
  padding: 15px 0 10px 20px;
  margin-left: 18px;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 0.7em;
  color: ${UserInterface.colors.darkGrey};
  font-weight: ${UserInterface.fonts.weight.medium};
`

export const PanelList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  li {
    cursor: pointer;
    padding-left: 10px;
    &:hover {
      background-color: ${UserInterface.colors.clearBlue};
    }
  }
`
export const Arrow = styled(props => <Svg {...props} />)`
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
`
