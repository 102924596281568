import React, { lazy, Suspense } from 'react'

const ReactSelect = lazy(() =>
  import('react-select').then(module => ({ default: module.default }))
)

const Select = ({ handleChange, options, placeholder, value, ...props }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ReactSelect
        classNamePrefix="react-select"
        onChange={option => handleChange && handleChange(option)}
        options={options}
        placeholder={placeholder}
        value={value}
        {...props}
      />
    </Suspense>
  )
}

export default Select
