import { ReactNode } from 'react'
import { Colors } from 'src/styles/type'

export type TClassName = string | undefined

export interface ILink {
  children?: React.ReactNode
  className?: string
  href?: string
  nofollow?: boolean
  isStyled?: boolean
  target?: string
  onClick?: () => void
}

export interface IEmoji {
  children?: React.ReactNode
}

export interface ITooltip {
  children?: React.ReactNode
}

export interface ITextBox {
  datas: Array<{
    title: string
    text: string
  }>
  hoverAnimation?: boolean
}

export interface ITeam {
  team: Array<{
    image: string
    name: string
    job: string
    maxDpi?: number
  }>
}

export interface ISvg {
  className?: string
  color?: keyof Colors
  colorSecondary?: keyof Colors
  height?: string
  src: string
  visibleByDefault?: boolean
  width?: string
  alt?: string
}

export interface ISpace {
  size?: string
  className?: string
}

export interface IBoxCustom {
  align?: EBoxAlign
  children?: ReactNode
  overflowLeft?: boolean
  overflowRight?: boolean
  overflowVertical?: boolean
  background?: string
  width?: number
  custom?: boolean
}

export interface ISvgStyledProps {
  color?: keyof Colors
  height?: string
  width?: string
  $colorSecondary?: keyof Colors
  src: string
  alt?: string
  loading?: () => JSX.Element
}

export interface IAdaptiveImage {
  src: string
  className?: string
  alt: string
  type?: EImageType
  align?: string
  width?: string
  height?: string
  maxDpi?: number
  visibleByDefault?: boolean
  inline?: string
  roundShape?: string
  id?: string
  style?: string
  isFullSize?: boolean
  disableLazyLoad?: boolean
  verticalMargin?: number
}

export enum EImageType {
  PNG = 'png',
  JPG = 'jpg',
  WEBP = 'webp',
  SVG = 'svg',
}

export enum EBoxAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum ETextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}
