import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { UserInterface, Text, Tag, Flex, Link } from '../../..'
import { Box } from '@rebass/grid'
import { formatMessage } from '../../../../utils/translations'
import btoa from 'btoa'
import { ITagsFilter } from './type'

const TagWrapper = styled(props => <Flex {...props} />)`
  margin-top: 10px;
  background-color: #fff;
  border-radius: 32.75px;
  box-shadow: 0 1px 6px 0 rgb(222 222 222);
  padding: 20px 20px 10px;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    flex-direction: column;
  }
`

const TagLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
  }
`

const TagStyled = styled(props => <Tag {...props} />)`
  font-size: 16px;
  padding: 10px 15px;
  &:hover {
    background: #00b094;
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
    text-align: center;
  }
`

const TextStyled = styled(props => <Text {...props} />)`
  margin-bottom: 10px;
  font-size: 16px !important;
  color: #4a4a4a;
  font-weight: bold !important;
  margin-right: 20px;
`

const TextBox = styled(props => <Box {...props} />)`
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    margin: auto;
    cursor: pointer;
  }
`

const TagBox = styled(({ ...rest }) => <Box {...rest} />)`
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    ${({ isVisible }) => !isVisible && 'display: none;'}
    width: 100%;
    margin-top: 8px;
  }
`

const TagsFilter: FC<ITagsFilter> = ({ blogPath, topics, translations }) => {
  const [isVisible, setIsVisible] = useState(false)
  const getTopicSlug = (topic: string) => {
    const topicSlug = topic.toLowerCase().replace(new RegExp('&', 'g'), '')
    const splitedTopicSlug = topicSlug.split(' ')
    return splitedTopicSlug.filter(topic => topic.length).join('-')
  }

  return (
    <TagWrapper
      custom={false}
      alignItems="center"
      justifyContent="space-between"
    >
      <TextBox
        width="auto"
        onClick={() => {
          setIsVisible(!isVisible)
        }}
      >
        <TextStyled>
          {formatMessage('tagsfilter_text', translations)}
        </TextStyled>
      </TextBox>
      <TagBox width="auto" isVisible={isVisible}>
        <TagLink href={btoa(`${blogPath}/`)}>
          <TagStyled>{formatMessage('tagsfilter_all', translations)}</TagStyled>
        </TagLink>
        {topics.map((topic, i) => (
          <TagLink
            key={i}
            href={btoa(`${blogPath}/tag/${getTopicSlug(topic)}/`)}
          >
            <TagStyled>{topic}</TagStyled>
          </TagLink>
        ))}
      </TagBox>
    </TagWrapper>
  )
}

export default TagsFilter
