import React, { useEffect, useState } from 'react'
import { StickyContainer, Sticky } from 'react-sticky'
import ReactMarkdown from 'react-markdown'
import {
  AuthorAvatar,
  AuthorName,
  AuthorWrap,
  BlogGrid,
  BlogMain,
  BlogPageTitle,
  BlogSection,
  CardPostRecommended,
  FavPostsAside,
  FreeTrial,
  HeaderShadow,
  Link,
  ListCtaBlock,
  PostBody,
  PostCover,
  Progress,
  Tag,
  TagWrap,
  Layout,
} from '../../index'
import btoa from 'btoa'
import { removeDoubleSlashFromUrl } from '../../../utils/string'

import { getLang, getTranslations } from '../../../utils/context'

import {
  getFaqItems,
  getFaq2Items,
  cleanBody,
  getCtaCode,
  cleanFaq,
  cleanFaq2,
} from './utils'

import { getNewsArticle } from './data'

const Post = ({
  data: {
    hubspotPost: post,
    recommendedposts: { edges: recommendedPosts },
    favoritesposts: { edges: favoritesPosts },
  },
  pageContext: {
    blogPath,
    hubspotCta,
    brand,
    rating,
    blogBaseUrl,
    faq,
    faq2,
    favoritesSlugs: favSlugs,
    recommendedSlugs,
    recommendedSlugsObfuscation,
    favoritesSlugsObfuscation,
    hubspotNewsletter,
  },
  components: { Newsletter },
}) => {
  const translations = getTranslations()

  const [richSnippets, setRichSnippets] = useState(undefined)
  const [sideBarLoaded, setSideBarLoaded] = useState(false)
  const [bodyCleaned, setBodyCleaned] = useState(false)
  const [scrolled, setScrolled] = useState(0)
  const [sidebarCta, setSidebarCta] = useState(
    getCtaCode(hubspotCta.code, hubspotCta.uuid)
  )

  const [sidebarCtaTitle, setSidebarCtaTitle] = useState(
    translations.post.sidebarCtaTitle
  )

  useEffect(() => {
    post.body = cleanBody(post.body, blogPath, hubspotCta)

    const scrollProgress = () => {
      const element = document.documentElement
      const height = element.scrollHeight - (element.clientHeight + 1500)
      setScrolled(`${(element.scrollTop / height) * 100}%`)
    }

    // Progress bar event
    window?.addEventListener('scroll', scrollProgress, {
      passive: true,
    })

    const obfuscatedLinkClick = event => {
      window.location.href = atob(event.target.getAttribute('data-64'))
    }

    // Obfuscation event
    const obfuscatedLinks = document.querySelectorAll('[data-64]')
    if (obfuscatedLinks && obfuscatedLinks.length) {
      obfuscatedLinks.forEach(obfuscatedLink => {
        obfuscatedLink.addEventListener('click', obfuscatedLinkClick, {
          passive: true,
        })
      })
    }

    setBodyCleaned(true)

    // Clean eventListener
    return () => {
      window?.removeEventListener('scroll', scrollProgress)
    }
  }, [blogPath, hubspotCta, post])

  useEffect(() => {
    if (sideBarLoaded === false) {
      // Newsletter
      const newsletter = post.body.match(/{{newsletter\((.+)\)}}/is)
      if (newsletter) {
        const [newsletterGlobal] = newsletter
        post.body = post.body.replace(newsletterGlobal, '')
      }

      // Sidebar CTA
      const sidebarCtas = post.body.match(
        /\{\{sidebar-cta\((.+)\)\}\}(.+)\{\{\/sidebar-cta\}\}/is
      )
      if (sidebarCtas) {
        const [sidebarCtaGlobal, sidebarCtaTitle, sidebarCtaContent] =
          sidebarCtas
        setSidebarCta(sidebarCtaContent)
        setSidebarCtaTitle(sidebarCtaTitle)

        post.body = post.body.replace(sidebarCtaGlobal, '')
      }
      setSideBarLoaded(true)
    }
  }, [post, sideBarLoaded])

  useEffect(() => {
    const newsArticle = getNewsArticle(post, blogBaseUrl, blogPath, brand)

    cleanFaq(post)
    cleanFaq2(post)
    const faqItems = getFaq2Items(faq2) || getFaqItems(faq)
    const faqPage = !faqItems
      ? {}
      : {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: faqItems.map(faqItem => ({
            '@type': 'Question',
            name: faqItem.title,
            acceptedAnswer: {
              '@type': 'Answer',
              text: `${faqItem.text}${
                faqItem.link
                  ? ` &lt;a href="https://${faqItem.link}"&gt;En savoir plus&lt;/a&gt;`
                  : ''
              }`,
            },
          })),
        }

    const richSnippets = []
    richSnippets.push(newsArticle)
    if (faqItems) {
      richSnippets.push(faqPage)
    }

    if (rating) {
      richSnippets.push({
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'AssoConnect',
        applicationCategory: 'BusinessApplication',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: rating.aggregate.value,
          ratingCount: rating.aggregate.count,
        },
        offers: {
          '@type': 'Offer',
          price: rating.pricing.value,
          priceCurrency: rating.pricing.currency,
        },
      })
    }
    setRichSnippets(richSnippets)
  }, [blogBaseUrl, blogPath, brand, faq, faq2, post, rating])

  if (!bodyCleaned) {
    return <></>
  }

  return (
    <Layout
      hreflangs={[
        {
          lang: getLang(),
          href: `${removeDoubleSlashFromUrl(blogBaseUrl + blogPath)}/${
            post.slug
          }/`,
        },
      ]}
      headerBackgroundColor="white"
      header="BLOG"
      homePath={blogPath}
      isBlog={true}
      meta={{
        title: post.meta.title,
        description: post.meta.description,
        image: post.feature_image.url,
      }}
      richSnippets={JSON.stringify(richSnippets)}
    >
      <style>{`body { background: white !important; }`}</style>
      <Progress scroll={scrolled} className="hidden-desktop" />
      <Progress scroll={scrolled} className="hidden-mobile" />
      <HeaderShadow />
      <BlogSection paddingBottom="40px">
        <PostCover
          image={post.feature_image.url}
          alt={post.feature_image.alt_text}
        />
        <BlogGrid>
          <BlogMain>
            <BlogPageTitle>{post.name || post.title}</BlogPageTitle>
            <div className="hidden-mobile">
              {post.author && (
                <AuthorWrap>
                  <AuthorAvatar
                    src={post.author.avatar}
                    alt={post.author.AuthorName}
                  />
                  <AuthorName color="#838383">
                    {post.author.full_name}
                  </AuthorName>
                </AuthorWrap>
              )}
            </div>
            <PostBody>
              <main style={{ maxWidth: ' 90vw' }}>
                <ReactMarkdown source={post.body} escapeHtml={false} />
              </main>
            </PostBody>
            <TagWrap>
              {post.topics.map((topic, i) => (
                <Link key={i} href={btoa(`${blogPath}/tag/${topic.slug}/`)}>
                  <Tag>{topic.name}</Tag>
                </Link>
              ))}
            </TagWrap>
            <div style={{ width: '100%' }} />
            <div className="hidden-desktop">
              {post.author && (
                <AuthorWrap>
                  <AuthorAvatar
                    src={post.author.avatar}
                    alt={post.author.AuthorName}
                  />
                  <AuthorName color="#838383">
                    {post.author.full_name}
                  </AuthorName>
                </AuthorWrap>
              )}
            </div>
          </BlogMain>
          <ListCtaBlock>
            <Newsletter
              hubspotId={hubspotNewsletter.id}
              hubspotCode={hubspotNewsletter.code}
              buttonHref={hubspotNewsletter.href}
              title={hubspotNewsletter.title}
              subtitle={hubspotNewsletter.subtitle}
              translations={translations}
            />
            <FavPostsAside
              blogPath={blogPath}
              favoritesPosts={favoritesPosts}
              favoritesSlugs={favSlugs}
              favoritesSlugsObfuscation={favoritesSlugsObfuscation}
              favoritesTitle={translations.favorites}
            />
            <StickyContainer
              style={{ height: 'calc(100% - 1135px)', width: '96%' }}
            >
              <Sticky topOffset={-60}>
                {({ style, isSticky }) => (
                  <div
                    style={{
                      ...style,
                      marginTop: isSticky ? '100px' : '0px',
                    }}
                  >
                    <FreeTrial cardtitle={sidebarCtaTitle}>
                      <ReactMarkdown source={sidebarCta} escapeHtml={false} />
                    </FreeTrial>
                  </div>
                )}
              </Sticky>
            </StickyContainer>
          </ListCtaBlock>
        </BlogGrid>
        <Newsletter
          hubspotId={hubspotNewsletter.id}
          hubspotCode={hubspotNewsletter.code}
          mobile
          buttonHref={hubspotNewsletter.href}
          title={hubspotNewsletter.title}
          subtitle={hubspotNewsletter.subtitle}
          style={{ marginTop: 30 }}
          translations={translations}
        />
      </BlogSection>
      <CardPostRecommended
        blogPath={blogPath}
        recommendedPosts={recommendedPosts}
        recommendedSlugs={recommendedSlugs}
        recommendedSlugsObfuscation={recommendedSlugsObfuscation}
        translations={translations}
      />
    </Layout>
  )
}

export default Post
