import React, { useState, FC } from 'react'
import { ITooltip } from './type'
import { Svg, UserInterface } from './index'
import styled from 'styled-components'

const TooltipWrapper = styled(props => <div {...props} />)`
  position: relative;
  display: inline-block;
`

const TooltipTextWrapper = styled(props => <div {...props} />)`
  position: absolute;
  font-family: ${UserInterface.fonts.family.roboto};
  font-size: 12px;
  font-weight: 400;
  background: ${UserInterface.colors.darkGrey} !important;
  color: ${UserInterface.colors.white};
  opacity: ${props => (props.$isHovered ? 1 : 0)};
  visibility: ${props => (props.$isHovered ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  padding: 10px;
  z-index: 999;
  border-radius: 5px;
  width: 300px;
  left: -142px;
  margin-top: 10px;
  &:before {
    content: '';
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: ${UserInterface.colors.darkGrey};
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

// Use a button to prevent https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-static-element-interactions.md
const FakeButton = styled(({ ...props }) => <button {...props} />)`
  // Remove button default style
  background: none;
  padding: 0;
  border: 0;
`

const Tooltip: FC<ITooltip> = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <TooltipWrapper>
      <FakeButton
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Svg src="components/tooltip/question-circle-light" width="18px" />
      </FakeButton>
      <TooltipTextWrapper $isHovered={isHovered} {...props}>
        {children}
      </TooltipTextWrapper>
    </TooltipWrapper>
  )
}

export default Tooltip
