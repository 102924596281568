import { ITranslations } from './type'

/**
 * Returns the translation for given id
 * @param id
 * @param translations
 */
export const formatMessage = (
  id: string,
  translations: ITranslations | undefined
) => (translations?.[id] ? translations[id] : '')
