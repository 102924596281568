import { removeDoubleSlashFromUrl } from '../../../utils/string'

export const getNewsArticle = (post, blogBaseUrl, blogPath, brand) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${removeDoubleSlashFromUrl(blogBaseUrl + blogPath)}/${
        post.slug
      }/`,
    },
    headline: `${post.meta.title}`,
    image: new Array(post.feature_image.url),
    datePublished: `${new Date(post.published).toISOString()}`,
    dateModified: `${new Date(post.updated).toISOString()}`,
    author: {
      '@type': 'Person',
      name: `${post?.author?.full_name || ''}`,
    },
    publisher: {
      '@type': 'Organization',
      name: brand,
      logo: {
        '@type': 'ImageObject',
        url: `${
          process.env.GATSBY_CDN_HOST
        }/common/logo/${brand.toLowerCase()}.png`,
      },
    },
  }
}
