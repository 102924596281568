import React, { FC } from 'react'
import styled from 'styled-components'
import { ITestimonialTextContent } from '../type'
import TestimonialTextContent from '../components/TextContent'
import { UserInterface } from '../..'

/**
 * Style
 */

export const TextContainer = styled(props => <div {...props} />)`
  position: relative;
  max-width: 100%;
  width: inherit;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    padding: 0;
  }
`

/**
 * Component
 */
const Testimonial: FC<ITestimonialTextContent> = ({
  statement,
  authorName,
  authorRole,
  nonprofitName,
  nonprofitDescription,
  nonprofitNumberOfMembers,
  link,
}) => {
  return (
    <>
      <TextContainer>
        <TestimonialTextContent
          statement={statement}
          authorName={authorName}
          authorRole={authorRole}
          nonprofitName={nonprofitName}
          nonprofitDescription={nonprofitDescription}
          nonprofitNumberOfMembers={nonprofitNumberOfMembers}
          link={link}
        />
      </TextContainer>
    </>
  )
}

export default Testimonial
