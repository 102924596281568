import React, { FC, useEffect, useRef } from 'react'
import Typed from 'typed.js'
import { checkSize } from 'src/utils/validators'
import classNames from 'classnames'
import styled from 'styled-components'
import { Highlight } from 'src/components'

import { EBreakpoint } from 'src/styles/type'
import { IAnimationTyping } from './type'

const TextContainer = styled.div`
  min-height: 6rem;
  margin-bottom: 1rem;

  @media screen and (min-width: ${EBreakpoint.EXTRA_SMALL_MOBILE}) {
    min-height: 4.5rem;
  }

  @media screen and (min-width: ${EBreakpoint.SMALL_MOBILE}) {
    min-height: 3rem;
  }

  @media screen and (min-width: ${EBreakpoint.MOBILE}) {
    min-height: 4.5rem;
  }

  @media screen and (min-width: ${EBreakpoint.LARGE_TABLE}) {
    min-height: 3.5rem;
  }
`

/**
 * Component
 */

const AnimationTyping: FC<IAnimationTyping> = ({
  data,
  className,
  withHighlight,
}) => {
  const typedRef = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    const validateData = data.map(str => checkSize(str, 50).response)
    const options = {
      strings: validateData,
      typeSpeed: 50,
      backSpeed: 50,
      loopCount: Infinity,
      loop: true,
    }
    const typed = new Typed(typedRef.current, options)

    return () => {
      typed.destroy()
    }
  }, [data])

  return (
    <TextContainer>
      {withHighlight ? (
        <Highlight>
          <span ref={typedRef} className={classNames(className)} />
        </Highlight>
      ) : (
        <span ref={typedRef} className={classNames(className)} />
      )}
    </TextContainer>
  )
}

export default AnimationTyping
