import React from 'react'
import styled from 'styled-components'
import { Text, Svg, UserInterface } from '../'
import classNames from 'classnames/bind'

/**
 * Style
 */
const Cross = styled.div`
  position: absolute;
  left: 0;
  top: 2px;
`
const ItemStyled = styled.li`
  position: relative;
  margin: 20px;
`
const TextStyled = styled(({ ...props }) => <Text {...props} />)`
  margin-left: 36px;
  transition: 0.3s;
  color: ${({ color }) => UserInterface.colors[color]} !important;

  .slick-current & {
    color: ${UserInterface.colors.white} !important;
  }
`

/**
 * Component
 */
const CrossList = ({ color = 'middleGrey', content, className }) => (
  <ul className={classNames(className)}>
    {content.map((field, i) => (
      <ItemStyled key={i} className="cross-list__item">
        <Cross>
          <Svg
            src="components/check-list/cross-circle-solid"
            width="1rem"
            color="darkGrey"
          />
        </Cross>
        <TextStyled color={color} align="left">
          {field}
        </TextStyled>
      </ItemStyled>
    ))}
  </ul>
)

export default CrossList
