import React, { FC } from 'react'
import styled from 'styled-components'

const BlogGridStyled = styled.div`
  display: flex;
`
const BlogGrid: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <BlogGridStyled>{children}</BlogGridStyled>
}

export default BlogGrid
