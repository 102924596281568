import { createGlobalStyle } from '@nfront/global-styles'
import { css } from 'styled-components'

import fonts from './fonts'
import colors from './colors'
import breakpoints from './breakpoints'
import animations from './animations'

const ReactSelectStyle = css`
  color: ${colors.extraDarkGrey};
  font-family: ${fonts.family.roboto};
  font-weight: ${fonts.weight.normal};
`

const GlobalStyle = createGlobalStyle`

/* Reset css */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio,video { margin: 0; padding: 0; border: 0; font-size: 100%; vertical-align: baseline;}
html, body{height:100%}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}
ol, ul {list-style: none;}
a{text-decoration: none; color: inherit; cursor:pointer}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after {content: ''; content: none;}
table {border-collapse: collapse; border-spacing: 0;}
*{box-sizing: border-box; outline: none}
#___gatsby{overflow-x: hidden}

/* Error */
.error {
  font-size: .8rem;
  font-weight: ${fonts.weight.normal};
  font-family: ${fonts.family.roboto};
  color: red;
}

/* Animations */
.animation--effect-bouncing,
.animation--effect-wobble, 
.animation--effect-max {
  transition: 0.3s;    
}
.animation--start-hover {
  &:hover .animation--effect-bouncing, &.animation--effect-bouncing:hover {
    transition-timing-function: ease-out;
  }
  &:hover .animation--effect-wobble, &.animation--effect-wobble:hover {
    animation: ${animations.wobble} 1s ease-in-out 1;
  }
  &:hover .animation--effect-max, &.animation--effect-max:hover {
    padding-right: 1.9rem;
  }
  /* Desktop mode */
  @media screen and (min-width: ${breakpoints.mobile}) {
    &:hover .animation--effect-max, &.animation--effect-max:hover {
      padding-right: 1.9rem;
    }
  }
}

/* Desktop / desktop hidden */
@media screen and (min-width: ${breakpoints.mobile}) {
  .hidden-desktop {
    display:none !important;
  }
}

@media screen and (max-width: ${breakpoints.mobile}) {
  .hidden-mobile {
    display: none !important; 
  }
}
@media screen and (min-width: ${breakpoints.largeTable}) {
  .hidden-largeTableDesktop {
    display:none !important;
  }
}
@media screen and (max-width: ${breakpoints.largeTable}) {
  .hidden-largeTableMobile {
    display: none !important; 
  }
}

/* Font size */
html, body {
  font-size: 18px;
}

body {
  background: linear-gradient(132.82deg , rgba(49, 107, 242, 0.07)  0%, #FFFFFF 49.5%, rgba(49, 107, 242, 0.07)  100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  }

@media screen and (max-width: ${breakpoints.mobile}) {
  body{
    background:  #FFFFFF;
      }
  }

/* react-select */
.react-select__multi-value:first-of-type {
  margin-top: 5px !important;
}

div[class^='css-'] {
  z-index: 999 !important;
}

.react-select__control {
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 50px;
  box-shadow: 0 2px 7px 0 #316bf233;
  min-height: 46px;
  margin: 10px 0;
  min-width: 220px;

  &:hover {
    .react-select__indicator {
      color: ${colors.middleGrey};
    }
  }
}
.react-select__value-container {
  ${ReactSelectStyle};
  padding-top: 0;
  padding-bottom: 0;
}
.react-select__multi-value {
  background-color: ${colors.middleGrey};
  color: ${colors.white};
  border-radius: 3px;
  margin-top: 0;

  .react-select__multi-value__label {
    color: ${colors.white};
    padding: 0px 3px 0px 6px;
    font-size: 0.875rem;
    line-height: 19px;
  }
  .react-select__multi-value__remove {
    color: ${colors.white};
  }
  .react-select__multi-value__remove:hover {
    background-color: ${colors.darkGrey};
  }
}
.react-select__menu {
z-index: 11 !important;
  ${ReactSelectStyle};
  .react-select__group-heading {
    ${ReactSelectStyle};
    font-size: 1rem;
    text-transform: none;
    font-weight: 700;
  }
}
.react-select__indicator {
  padding: 0 8px;
  color: ${colors.darkGrey};
}
.react-select__control--is-focused {
  box-shadow: 0 1px 3px 0 ${colors.lightGrey},
  0 0 0 0.2rem rgba(49, 107, 242, 0.25);
}
.react-select__placeholder {
  color: ${colors.middleGrey};
}
&.is-invalid .react-select__control {
  border: ${colors.red} solid 1px;
}
.react-select__control--is-disabled {
  background-color: ${colors.extraLightGrey};
}
.react-select__menu {
  z-index: 900;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.05),
  0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

  .react-select__option {
    ${ReactSelectStyle};
    color: ${colors.middleGrey};
    background-color: ${colors.white};

    &.react-select__option--is-focused {
      background-color: ${colors.lightGrey};
      color: ${colors.extraDarkGrey};
      &:active {
        background-color: ${colors.middleGrey};
        color: ${colors.white};
        small {
          color: ${colors.white};
        }
      }
    }
  }
}
.react-select__indicator-separator {
  display: none;
}
&:hover {
  .react-select__control {
    border: 1px solid transparent;
  }
  &.is-invalid .react-select__control {
    border: ${colors.red} solid 1px;
  }
  .react-select__indicator-separator {
    background-color: ${colors.darkGrey};
    transition: background-color 150ms;
  }
  .react-select__dropdown-indicator,
  .react-select__placeholder {
    ${ReactSelectStyle};
    color: ${colors.middleGrey};
    transition: color 150ms;
  }
}
   
`

export default GlobalStyle
