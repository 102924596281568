import React from 'react'
import { contact, login } from './links'

const networksNavigationFR = {
  navigationButtons: [login, contact],
  items: [
    {
      text: 'Votre besoin',
      boxes: [
        {
          children: [
            {
              text: 'Gérer mon asso de A à Z',
              icon: 'common/icon/multicolor/members',
              href: '/tarifs/offre-serenite/',
            },
            {
              text: 'Simplifier ma comptabilité',
              icon: 'common/icon/multicolor/accounting',
              href: '/tarifs/offre-comptabilite/',
            },
          ],
        },
        {
          children: [
            {
              text: 'BabyAsso : créer mon asso',
              icon: 'common/icon/multicolor/generosity',
              href: '/creer-association/',
            },
            {
              text: "Gratuit : collecter de l'argent",
              icon: 'common/icon/multicolor/donations',
              href: '/gratuit/',
            },
          ],
        },
      ],
      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            Nouveau : récupérer de l’argent avec le lien de paiement
          </>
        ),
        href: '/fonctionnalites/lien-de-paiement/',
      },
      width: 600,
    },
    {
      text: 'Fonctionnalités',
      boxes: [
        {
          children: [
            {
              text: 'Antennes autonomes',
              icon: 'common/icon/multicolor/connection',
              href: '#antenne',
            },
            {
              text: 'Base de données consolidée',
              icon: 'common/icon/multicolor/leasure',
              href: '#base',
            },
            {
              text: 'Comptabilité',
              icon: 'common/icon/multicolor/refund',
              href: '#compta',
            },
            {
              text: 'Communication harmonisée',
              icon: 'common/icon/multicolor/communication',
              href: '#comm',
            },
            {
              text: 'Sécurité renforcée',
              icon: 'common/icon/multicolor/security',
              href: '#secu',
            },
          ],
        },
      ],
      footer: {
        text: <>🤝 Notre accompagnement </>,
        href: '/reseaux/accompagnement/',
      },

      width: 300,
    },
    { text: 'Tarifs', href: '/tarifs/' },

    {
      text: 'Ressources',
      href: '/ressources/',
      boxes: [
        {
          children: [
            {
              text: 'Guides gratuits',
              href: '/ressources/guides-associations/',
            },
            {
              text: 'Formations personnalisées',
              href: '/formations/',
            },
            {
              text: 'Témoignages',
              href: '/blog/tag/t%C3%A9moignages/',
            },
          ],
        },
      ],
      footer: {
        text: <>💌 Newsletter </>,
        href: '/ressources/newsletter/',
      },
      width: 250,
    },
  ],
}

export default networksNavigationFR
