import React, { FC, Children, useRef } from 'react'
import styled from 'styled-components'

import { Dots, UserInterface } from '../..'
import { useCards } from '../../../hooks'
import { ISixCards } from './type'

/**
 * Style
 */

const Card = styled(props => <div {...props} />)`
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  .text {
    font-size: 0.9rem !important;

    @media (min-width: ${UserInterface.breakpoints.mobile}) {
      font-size: 1rem !important;
    }
  }

  &.fade-out {
    opacity: 0;
  }
  &.fade-in {
    opacity: 1;
  }
`

const CardContainer = styled(props => <div {...props} />)`
  background-color: ${UserInterface.colors['white']};
  border: 1px solid ${UserInterface.colors['lightGrey']};
  box-shadow: 3px 5px 9px -2px rgba(185, 185, 185, 0.36);
  border-radius: 15px;
  position: relative;
  top: 0;
  left: 0;
  width: 80%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  display: none;
  margin: 0 auto;
  padding: 1rem;
  min-height: 200px;

  &.fade-out {
    opacity: 0;
  }
  &.isCurrentCard {
    display: block;
  }
  &.isPrevCard {
    display: block;
    position: absolute;
    left: -75%;
  }
  &.isNextCard {
    display: block;
    position: absolute;
    right: -170%;
  }
  @media (min-width: 750px) {
    display: block;
    width: 30%;
    padding: 1rem;
    margin: 0.5rem;
    &.isPrevCard,
    &.isNextCard {
      display: block;
      position: initial;
    }
  }
`

const CardsContainer = styled.div`
  position: relative;
  overflow: hidden;
  @media (min-width: 750px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

/**
 * Cards Multi Component
 */
const SixCards: FC<ISixCards> = ({ children }) => {
  const childrenArray = Array.from(Array(Children.count(children)).keys())
  const cardsAmount = childrenArray.length

  const {
    displayNextCard,
    displayPreviousCard,
    displayCardFromId,
    isSwiping,
    cardsStatus,
  } = useCards(cardsAmount)

  const swipeRef = useRef(null)
  let startX = 0
  let currentX = 0

  const handleTouchStart = (e: React.TouchEvent) => {
    if (childrenArray.length === 1) return
    startX = e.touches[0].clientX
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    currentX = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    const difference = currentX - startX
    if (difference > 50) {
      displayPreviousCard()
    } else if (difference < -50) {
      displayNextCard()
    }
  }

  return (
    <>
      <CardsContainer>
        {React.Children.map(children, (child, index) => {
          const isCurrentCard = cardsStatus.currentCardId === index

          return (
            <CardContainer
              key={index}
              ref={swipeRef}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              className={`${isSwiping ? 'fade-out' : 'fade-in'} 
              ${isCurrentCard ? 'isCurrentCard' : ''}
              ${index === cardsStatus.previousCardId ? 'isPrevCard' : ''} ${
                index === cardsStatus.nextCardId ? 'isNextCard' : ''
              } `}
              $isCurrentCard={isCurrentCard}
            >
              {React.cloneElement(child as React.ReactElement, {
                key: index,
                children: React.Children.map(
                  (child as React.ReactElement).props.children,
                  (subChild, subIndex) => <Card key={subIndex}>{subChild}</Card>
                ),
              })}
            </CardContainer>
          )
        })}
      </CardsContainer>
      <Dots
        items={childrenArray}
        displayItemId={displayCardFromId}
        currentItemId={cardsStatus.currentCardId}
      />
    </>
  )
}

export default SixCards
