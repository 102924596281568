import React, { FC } from 'react'
import styled from 'styled-components'
import { Box, Flex, UserInterface, Svg, Link } from '..'
import { formatMessage } from '../../utils/translations'
import { getTranslations } from 'src/utils/context'

/**
 * Style
 */

const NavigationToggleStyled = styled(props => <Flex {...props} />)`
  height: 80px;
  flex-direction: row !important; /* Force row direction in mobile mode */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid ${UserInterface.colors.lightGrey};
  z-index: 25;

  svg path {
    ${({ navigationMobileIsOpened }) =>
      navigationMobileIsOpened
        ? `fill: ${UserInterface.colors.blue}`
        : `fill: ${UserInterface.colors.white}`} !important;
  }

  ${({ navigationMobileIsOpened }) =>
    navigationMobileIsOpened
      ? `background-color: ${UserInterface.colors.white}`
      : `background-color: ${UserInterface.colors.blue}`} !important;

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`
const NavigationToggleLogo = styled(props => <Svg {...props} />)`
  text-align: left !important;
`
const NavigationToggleBurger = styled.div<{
  navigationMobileIsOpened: boolean
}>`
  margin-left: auto;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;

  /* Mobile navigation open */
  ${({ navigationMobileIsOpened }) =>
    navigationMobileIsOpened && `transform: rotate(-45deg);`};
`
const NavigationToggleBurgerLine = styled.div<{
  navigationMobileIsOpened: boolean
  navigationIsFixed: boolean
  navigationType: string
}>`
  border-radius: 5px;
  width: 100%;
  height: 2px;

  &.navigation__line--half {
    width: 50%;
  }
  &.navigation__line--start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;

    /* Mobile navigation open */
    ${({ navigationMobileIsOpened }) =>
      navigationMobileIsOpened && `transform: rotate(-90deg) translateX(3px);`};
  }
  &.navigation__line--end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;

    /* Mobile navigation open */
    ${({ navigationMobileIsOpened }) =>
      navigationMobileIsOpened &&
      `transform: rotate(-90deg) translateX(-3px);`};
  }

  ${({ navigationMobileIsOpened }) =>
    navigationMobileIsOpened
      ? `background-color: ${UserInterface.colors.middleGrey}`
      : `background-color: ${UserInterface.colors.white}`} !important;
`

/**
 * Component
 */
const NavigationToggle: FC<{
  navigationToggleBurgerFunction: () => void
  navigationType: string
  navigationMobileIsOpened: boolean
  navigationIsFixed: boolean
  homePath: string
}> = ({
  navigationToggleBurgerFunction,
  navigationType,
  navigationMobileIsOpened,
  navigationIsFixed,
  homePath,
}) => {
  const translations = getTranslations()
  return (
    <NavigationToggleStyled
      navigationMobileIsOpened={navigationMobileIsOpened}
      navigationIsFixed={navigationIsFixed}
      alignItems="center"
      navigationType={navigationType}
    >
      <Box width="auto">
        <Link href={homePath}>
          <NavigationToggleLogo
            height="40px"
            color="blue"
            src={`common/logo/${formatMessage(
              'site_name',
              translations
            )}-simple`}
          />
        </Link>
      </Box>
      <Box width="auto" ml="auto">
        <NavigationToggleBurger
          navigationMobileIsOpened={navigationMobileIsOpened}
          onClick={navigationToggleBurgerFunction}
        >
          <NavigationToggleBurgerLine
            navigationIsFixed={navigationIsFixed}
            navigationMobileIsOpened={navigationMobileIsOpened}
            className="navigation__line--half navigation__line--start"
            navigationType={navigationType}
          />
          <NavigationToggleBurgerLine
            navigationIsFixed={navigationIsFixed}
            navigationMobileIsOpened={navigationMobileIsOpened}
            navigationType={navigationType}
          />
          <NavigationToggleBurgerLine
            navigationIsFixed={navigationIsFixed}
            navigationMobileIsOpened={navigationMobileIsOpened}
            className="navigation__line--half navigation__line--end"
            navigationType={navigationType}
          />
        </NavigationToggleBurger>
      </Box>
    </NavigationToggleStyled>
  )
}
export default NavigationToggle
