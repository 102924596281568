import React, { FC } from 'react'
import styled from 'styled-components'
import { ITagWrap } from './type'

const TagWrapStyled = styled(({ ...props }) => <div {...props} />)`
  margin-bottom: 20px;
  min-height: ${({ $minHeight }) => ($minHeight ? $minHeight : 'auto')};
`

const TagWrap: FC<ITagWrap> = ({ children, minHeight }) => {
  return <TagWrapStyled $minHeight={minHeight}>{children}</TagWrapStyled>
}

export default TagWrap
