import React from 'react'
import styled from 'styled-components'
import { BackButton, Section, UserInterface } from '../index'

const SubscriptionHeader = styled.header`
  min-height: 66px;
  background: ${UserInterface.colors.white};
  display: flex;
  align-items: center;

  .navigation__logo {
    svg {
      height: 40px;

      .svg--color-primary {
        fill: ${UserInterface.colors.blue};
      }
    }
  }
`

const SubscriptionSection = styled(props => <Section {...props} />)`
  width: 100%;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;

  & > div {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
`

const NavigationTitle = styled.h1`
  font-family: ${UserInterface.fonts.family.poppins};
  font-size: 32px;
  font-weight: ${UserInterface.fonts.weight.normal};
  color: ${UserInterface.colors.extraDarkGrey};
`

/**
 * Component
 */
const NavigationSubscription = ({ backLink, title, translations }) => {
  return (
    <SubscriptionHeader>
      <SubscriptionSection haveBackground={false}>
        <NavigationWrapper>
          {backLink && (
            <BackButton
              backLink={backLink}
              textContent={translations.backButton}
            />
          )}
          <NavigationTitle>{title}</NavigationTitle>
        </NavigationWrapper>
      </SubscriptionSection>
    </SubscriptionHeader>
  )
}

export default NavigationSubscription
