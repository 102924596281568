import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Box, Flex, Svg, UserInterface, Link } from '..'
import NavigationChild from './NavigationChild'
import NavigationButtons from './NavigationButtons'
import NavigationItem from './NavigationItem'
import NavigationToggle from './NavigationToggle'
import { formatMessage } from '../../utils/translations'

/**
 * Helpers css
 */
const navigationCommonCss = css`
  > .navigation__arrow .svg--color-primary,
  .navigation__logo .svg--color-primary {
    fill: ${UserInterface.colors.blue};
  }
  .navigation__buttons {
    .button--theme-line-white {
      color: ${UserInterface.colors.middleGrey};
      border-color: ${UserInterface.colors.middleGrey};
    }
    .button--theme-link-white {
      color: ${UserInterface.colors.middleGrey};
    }
  }
  .navigation__inner {
    opacity: 1;
    color: ${UserInterface.colors.middleGrey};

    &:hover {
      color: ${UserInterface.colors.blue};

      > .navigation__arrow .svg--color-primary {
        fill: ${UserInterface.colors.blue};
      }
    }
    > a {
      color: ${UserInterface.colors.middleGrey};

      &:hover {
        color: ${UserInterface.colors.blue};
      }
    }
    .navigation__arrow {
      opacity: 1;

      .svg--color-primary {
        fill: ${UserInterface.colors.darkGrey};
      }
    }
  }
  .phoneNumber svg path {
    fill: ${UserInterface.colors.darkGrey};
  }
`

const navigationWhiteCss = css`
  background-color: ${UserInterface.colors.white};
  .button--theme-link-yellow {
    color: ${UserInterface.colors.middleGrey} !important;
  }
  ${navigationCommonCss}
  .wisp {
    svg path {
      stroke: black;
    }
  }
`

const navigationTransparentCss = css`
  background-color: transparent;
  ${navigationCommonCss}
`

const navigationClearBlueCss = css`
  background-color: ${UserInterface.colors.clearBlue};
  ${navigationCommonCss}
`

const navigationBlueCss = css`
  > .navigation__arrow .svg--color-primary,
  .navigation__logo .svg--color-primary {
    fill: ${UserInterface.colors.white};
  }

  .wisp {
    svg path {
      stroke: white;
    }
  }
  .navigation__buttons {
    .button--theme-line-white {
      color: ${UserInterface.colors.white};
      border-color: ${UserInterface.colors.white};
    }
    .button--theme-link-white {
      color: ${UserInterface.colors.white};
    }

    .button--theme-link-yellow {
      color: ${UserInterface.colors.white};
    }

    .button--theme-link-blue {
      border: 1px solid ${UserInterface.colors.white};
    }
  }
  .navigation__inner {
    color: ${UserInterface.colors.white};

    &:hover {
      opacity: 1;
    }
    > a {
      color: ${UserInterface.colors.white};

      &:hover {
        opacity: 1;
      }
    }
    .navigation__arrow {
      .svg--color-primary {
        fill: ${UserInterface.colors.white};
      }
    }
  }
  .phoneNumber {
    opacity: 0.8;
  }
  .phoneNumber span {
    color: ${UserInterface.colors.white};
  }
  .phoneNumber svg path {
    fill: ${UserInterface.colors.white};
  }
`

const navigationFixedCss = css`
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    position: fixed;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.19);
    ${navigationWhiteCss};
  }
`
const navigationInnerFixedCss = css`
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    && {
      height: 60px;
      margin-bottom: 0;
      align-items: center;

      .navigation__items {
        margin-bottom: 0px;
      }

      .wisp {
        padding-bottom: 0;
        padding-top: 0.3rem;
      }
    }
  }
`

const NavigationChildFooterText = styled.span`
  white-space: normal;
`

/**
 * Style
 */
const NavigationMargin = styled.div`
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    height: 59px;
  }
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    height: 100px;
  }
`
const NavigationStyled = styled.nav`
  transition: background-color ease-out 0.2s;
  background-color: ${UserInterface.colors.blue};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  padding-left: 2.5rem;

  /* Color */
  ${({ headerBackgroundColor }) => {
    if (headerBackgroundColor === 'white') {
      return navigationWhiteCss
    }
    if (headerBackgroundColor === 'transparent') {
      return navigationTransparentCss
    }
    if (headerBackgroundColor === 'blue') {
      return navigationBlueCss
    }
    if (headerBackgroundColor === 'clearBlue') {
      return navigationClearBlueCss
    }
  }};

  /* Scroll Fixed (only desktop mode) */
  ${({ navigationIsFixed }) => navigationIsFixed && navigationFixedCss};
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    padding-left: 0;
    transition: all ease-out 0.2s;
    ${({ navigationMobileIsOpened }) =>
      !navigationMobileIsOpened && 'left:-100%'};
    position: fixed;
    bottom: 0;
    ${navigationWhiteCss};
  }
`
const NavigationInner = styled(props => <Flex {...props} />)`
  /* Specific style for all features */
  .navigation__child--all-features {
    color: ${UserInterface.colors.middleGrey};
    font-weight: ${UserInterface.fonts.weight.bold};

    /* Desktop mode */
    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      border-top: 1px solid ${UserInterface.colors.extraLightGrey};
      text-align: center;
      padding-top: 12px;
      margin-top: 0;
    }
  }

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    && {
      height: 85px;
      margin-bottom: 15px;
    }
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    height: calc(100% - 60px);
    margin-top: 60px;
    overflow-y: scroll;
  }
  /* Scroll fixed (only desktop mode) */
  ${({ navigationIsFixed }) => navigationIsFixed && navigationInnerFixedCss};
`
export const NavigationLogoWrapper = styled(props => <Box {...props} />)`
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`
export const NavigationLogo = styled(props => <Svg {...props} />)`
  display: block;
  text-align: center;
  ${({ navigationIsFixed }) => navigationIsFixed && `margin: auto;`}
`
const NavigationChildTitle = styled.div`
  margin: 18px 0;
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: ${UserInterface.fonts.weight.bold};
  color: ${UserInterface.colors.middleGrey};
  font-size: 14px;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    width: 270px;
    margin: 25px auto 0;
  }
`
// Fix https://gitlab.com/assoconnect/assoconnect/-/issues/18784
const NavigationItemWrapper = styled(props => <Box {...props} />)`
  white-space: nowrap;

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: flex;
    justify-content: right;
    align-items: end;
    width: 100%;
  }
`

const WisepopContainer = styled.div`
  padding-bottom: 0.4rem;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`

const WisepopBell = styled.svg`
  margin: O.4rem;

  path {
    stroke: black;
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    path {
      stroke: white;
    }
  }
`
/**
 * Component
 */
const Navigation = ({
  headerBackgroundColor,
  homePath,
  isBlog,
  items,
  navigationButtons,
  translations,
}) => {
  const [navigationChildIconLoad, setNavigationChildIconLoad] = useState(false)
  const [navigationIsFixed, setNavigationIsFixed] = useState(false)
  const [navigationMobileIsOpened, setNavigationMobileIsOpened] =
    useState(false)

  useEffect(() => {
    const fixNavBar = () => {
      if (!isMounted) return
      // Breakpoint
      let breakpoint
      if (
        window?.innerWidth >
        parseInt(UserInterface.breakpoints.mobile.slice(0, -2))
      ) {
        breakpoint = 100
      } else {
        breakpoint = 0
      }
      // Set state
      if (window?.pageYOffset > breakpoint) {
        if (!navigationIsFixed) {
          setNavigationIsFixed(true)
        }
      } else if (navigationIsFixed) {
        setNavigationIsFixed(false)
      }
      // Toggle overflow of body
      if (navigationMobileIsOpened) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
    }

    let isMounted = true

    // Fixed navigation on scroll
    if (isMounted) {
      document.addEventListener('scroll', fixNavBar, {
        passive: true,
      })
    }

    return () => {
      window?.removeEventListener('scroll', fixNavBar)
      isMounted = false
    }
  }, [navigationIsFixed, navigationMobileIsOpened])

  /**
   * Functions
   */
  // Update state after hover the navigation
  const handleNavigationItemsMouseOver = () => {
    if (!navigationChildIconLoad) {
      setNavigationChildIconLoad(true)
    }
  }

  // Toggle mobile navigation
  const handleNavigationToggleBurger = () => {
    setNavigationMobileIsOpened(!navigationMobileIsOpened)
  }

  const getNavigationLogoSrc = (navigationIsFixed, isBlog) => {
    const logoSuffix = isBlog ? '-blog' : ''

    return navigationIsFixed
      ? `common/logo/${formatMessage('site_name', translations)}-simple`
      : `common/logo/${formatMessage('site_name', translations)}${logoSuffix}`
  }

  return (
    <>
      <NavigationMargin />
      <NavigationToggle
        navigationIsFixed={navigationIsFixed}
        navigationToggleBurgerFunction={handleNavigationToggleBurger}
        navigationMobileIsOpened={navigationMobileIsOpened}
        translations={translations}
        navigationType={headerBackgroundColor}
        homePath={homePath}
      />
      <NavigationStyled
        headerBackgroundColor={headerBackgroundColor}
        navigationIsFixed={navigationIsFixed}
        navigationMobileIsOpened={navigationMobileIsOpened}
      >
        <NavigationInner
          navigationIsFixed={navigationIsFixed}
          className="navigation__inner"
          alignItems="flex-end"
        >
          <NavigationLogoWrapper px="0" width="125px">
            <Link aria-label="Home" href={homePath} isStyled={false}>
              <NavigationLogo
                className="navigation__logo"
                navigationIsFixed={navigationIsFixed}
                color="white"
                width="auto"
                height={navigationIsFixed ? '38px' : '65px'}
                src={getNavigationLogoSrc(navigationIsFixed, isBlog)}
                visibleByDefault
              />
            </Link>
          </NavigationLogoWrapper>
          {!!items && (
            <NavigationItemWrapper
              className="navigation__items"
              width="auto"
              px={[0, 0]}
              mb={['40px', '13px']}
              onMouseOver={handleNavigationItemsMouseOver}
              onFocus={handleNavigationItemsMouseOver}
            >
              {items.map((navigationItem, i) => (
                <React.Fragment key={i}>
                  {navigationItem.boxes ? (
                    <NavigationItem
                      width={navigationItem.width}
                      navigationMobileIsOpened={navigationMobileIsOpened}
                      text={navigationItem.text}
                      href={navigationItem.href}
                      nofollow={navigationItem.nofollow}
                    >
                      {navigationItem.boxes.map((box, j) => (
                        <Box
                          key={j}
                          width="auto"
                          px={[0, '10px']}
                          pb={[0, '10px']}
                        >
                          <NavigationChildTitle>
                            {box.title}
                          </NavigationChildTitle>
                          {box.children.map((navigationChild, k) => (
                            <NavigationChild
                              arrow={navigationChild.arrow}
                              href={navigationChild.href}
                              icon={
                                navigationChild.icon && navigationChild.icon
                              }
                              key={k}
                              loadIcon={navigationChildIconLoad}
                              text={navigationChild.text}
                              nofollow={navigationChild.nofollow}
                            />
                          ))}
                        </Box>
                      ))}
                      {navigationItem.footer && (
                        <Box width={1} px={0}>
                          <NavigationChild
                            text={
                              <NavigationChildFooterText>
                                {navigationItem.footer.text}
                              </NavigationChildFooterText>
                            }
                            arrow
                            className="navigation__child--all-features"
                            href={navigationItem.footer.href}
                            loadIcon={navigationChildIconLoad}
                            nofollow={navigationItem.footer.nofollow}
                          />
                        </Box>
                      )}
                    </NavigationItem>
                  ) : (
                    <NavigationItem
                      width={navigationItem.width}
                      navigationMobileIsOpened={navigationMobileIsOpened}
                      text={navigationItem.text}
                      href={navigationItem.href}
                      nofollow={navigationItem.nofollow}
                    />
                  )}
                </React.Fragment>
              ))}
            </NavigationItemWrapper>
          )}
          <WisepopContainer className="wisp">
            <WisepopBell
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              stroke="black"
              xmlns="<http://www.w3.org/2000/svg>"
            >
              <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
            </WisepopBell>
          </WisepopContainer>
          <Box
            width="auto"
            mt={[isBlog ? 0 : 'auto', 0]}
            ml="auto"
            px={[0, '30px']}
          >
            {navigationButtons && (
              <NavigationButtons
                translations={translations}
                navigationIsFixed={navigationIsFixed}
                navigationButtons={navigationButtons}
              />
            )}
          </Box>
        </NavigationInner>
      </NavigationStyled>
    </>
  )
}

export default Navigation
