import React, { FC } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { IHandWritten } from './type'
import { UserInterface } from '../'
import { EColor } from 'src/styles/type'

/**
 * Style
 */

interface TextStyled {
  color?: string
  rotate?: string
}

const TextStyled = styled.div<TextStyled>`
  font-family: ${UserInterface.fonts.family.caveat};
  font-weight: 700;
  rotate: ${({ rotate }) => rotate}deg;
  color: ${({ color }) => color};
`

/**
 * Component
 */
const HandWritten: FC<IHandWritten> = ({
  rotate = '0',
  color = EColor.WHITE,
  children,
}) => {
  /**
   * Output
   */

  return (
    <TextStyled rotate={rotate} color={color} className={classNames('text')}>
      {children}
    </TextStyled>
  )
}
HandWritten.displayName = 'HandWritten' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default HandWritten
