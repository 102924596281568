import { ESubscription } from './type'

export const login = {
  text: 'Login',
  href: 'https://app.springly.org/login',
  theme: `link-yellow`,
}

export const subscription = (type = ESubscription.SERENITY_US) => ({
  text: 'Try Springly',
  href: `https://app.springly.org/sign-up/first?subscription=${type}`,
  target: '_self',
  theme: 'link-blue',
})

export const discover = {
  text: 'Discover Springly',
  href: 'https://www.springly.org/en-us/',
  target: '_self',
  theme: 'link-white',
}
