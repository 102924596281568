import { Player } from '@lottiefiles/react-lottie-player'
import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { IWheelItem } from '../type'
import { UserInterface } from '../..'

/**
 * Style
 */

export const WheelItemContainer = styled(props => <div {...props} />)`
  position: absolute;
  z-index: 3;
  list-style: none;
  margin: 0;
  padding: 0;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: min(2vw, 0.9rem);
  font-size: max(min(2vw, 0.9rem), 0.6rem);
  opacity: ${props => (props.$isHidden ? '0.4' : '1')};

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  &.item-0 {
    grid-row: 1/2;
    grid-column: 5/5;
  }

  &.item-1 {
    left: 64%;
    top: 16%;
    width: 20%;
  }
  &.item-2 {
    left: 76%;
    top: 51%;
    width: 20%;
  }

  &.item-3 {
    left: 40%;
    top: 76%;
    width: 20%;
  }

  &.item-4 {
    left: 2%;
    top: 48%;
    width: 20%;
  }

  &.item-5 {
    left: 16%;
    top: 15%;
    width: 20%;
  }

  .icon {
    display: block;
    width: 55%;
    opacity: 1;
    transition: transform 0.15s ease-in-out, opacity 0.15s linear;
  }

  p {
    text-align: center;
  }
`

export const WheelText = styled(props => <p {...props} />)`
  position: absolute;
  left: 50%;
  top: 51%;
  transform: translate(-50%, -50%);
  opacity: ${props => (props.$displayDescription ? '1' : '0')};
  z-index: 1;
  width: 170px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1rem;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: ${UserInterface.breakpoints.extraSmallMobile}) {
    font-size: 1rem;
    line-height: 1.4rem;
    width: 230px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.table}) {
    font-size: 1.4rem;
    line-height: 1.8rem;
    width: 380px;
  }
`

/**
 * Component
 */
const WheelItem: FC<IWheelItem> = ({
  wheelIndex,
  highlightedIndex,
  setHighlightedIndex,
  animation,
  title,
  children,
}) => {
  const playerRef = useRef<Player>(null)

  useEffect(() => {
    if (highlightedIndex === wheelIndex) {
      playerRef.current?.play()
    }
  }, [highlightedIndex])

  return (
    <>
      <WheelItemContainer
        $isHidden={highlightedIndex !== 0 && wheelIndex !== highlightedIndex}
        className={`item-${wheelIndex}`}
        onMouseOver={() => {
          setHighlightedIndex(wheelIndex)
        }}
        onMouseEnter={() => {
          playerRef.current?.play()
        }}
        onMouseOut={() => {
          setHighlightedIndex(0)
        }}
        data-item-index="1"
      >
        <div className="icon">
          <Player
            src={`${process.env.GATSBY_CDN_HOST}/common/animation/${animation}.json`}
            ref={playerRef}
            className="player"
          />
        </div>
        <p data-item-index="1">{title}</p>
      </WheelItemContainer>
      <WheelText $displayDescription={wheelIndex === highlightedIndex}>
        {children}
      </WheelText>
    </>
  )
}

export default WheelItem
