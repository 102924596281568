import React, { FC } from 'react'
import styled from 'styled-components'
import { EBreakpoint } from 'src/styles/type'

const BlogSectionStyled = styled(({ ...props }) => <section {...props} />)`
  margin: 30px auto;
  padding-bottom: ${props => props.paddingBottom};
  max-width: 1140px;
  @media screen and (max-width: ${EBreakpoint.MOBILE}) {
    margin: 30px 5%;
    padding-bottom: 0px;
  }
`
const BlogSection: FC<{
  children: React.ReactNode
  paddingBottom?: string
}> = ({ children, paddingBottom }) => {
  return (
    <BlogSectionStyled paddingBottom={paddingBottom}>
      {children}
    </BlogSectionStyled>
  )
}

export default BlogSection
