import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames/bind'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Helmet } from 'react-helmet'

/**
 * Style
 */
const ImageWrapper = styled.div`
  text-align: ${({ align }) => align};
  margin: auto;
  ${({ inline }) => inline && 'display: inline-block; vertical-align:middle;'}
`

const ImageStyledFull = styled.img`
  width: 100% !important;
  height: 100% !important;
  &,
  .slick-slides & {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    width: auto;
    height: auto;
    ${({ roundShape }) => roundShape && getRoundShape(roundShape)}
  }
`

const ImageStyled = styled.img`
  &,
  .slick-slide & {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    ${({ roundShape }) => roundShape && getRoundShape(roundShape)}
  }
  .slick-slide & {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`

const getRoundShape = roundShape => {
  switch (roundShape) {
    case 'CIRCLE':
      return 'border-radius: 50%'
    case 'ROUNDED':
      return 'border-radius: 20px'
    case 'BLOB':
      return 'border-radius: 58% 45% 21% 15% / 73% 55% 50% 32%;'
    default:
      return ''
  }
}

/**
 * Component
 */
const AdaptiveImage = ({
  src,
  className,
  alt = '',
  type = 'png',
  align = 'center',
  width = '',
  height = '',
  maxDpi = 2,
  visibleByDefault = false,
  inline,
  roundShape = 'NONE',
  id,
  style,
  isFullSize = false,
  disableLazyLoad = false,
}) => {
  const sizeProps = {}
  if (width) {
    sizeProps.width = width
  }
  if (height) {
    sizeProps.height = height
  }

  return (
    <>
      {visibleByDefault && (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={`${process.env.GATSBY_CDN_HOST}/${src}-mobile.webp`}
            imageSrcSet={`${process.env.GATSBY_CDN_HOST}/${src}-mobile-2x.webp x2`}
            media="(max-width: 500px)"
          />
          <link
            rel="preload"
            as="image"
            href={`${process.env.GATSBY_CDN_HOST}/${src}.webp`}
            imageSrcSet={`${process.env.GATSBY_CDN_HOST}/${src}-2x.webp x2`}
            media="(min-width: 500px)"
          />
        </Helmet>
      )}
      <ImageWrapper
        width={width}
        align={align}
        height={height}
        className={classNames('image', className)}
        inline={inline}
        style={style}
        id={id}
      >
        <LazyLoadComponent threshold={400} visibleByDefault={visibleByDefault}>
          <picture>
            <source
              type="image/webp"
              media="(max-width: 500px)"
              srcSet={`${process.env.GATSBY_CDN_HOST}/${src}-mobile.webp, ${process.env.GATSBY_CDN_HOST}/${src}-mobile-2x.webp 2x`}
            />
            <source
              type="image/webp"
              media="(min-width: 500px)"
              srcSet={
                `${process.env.GATSBY_CDN_HOST}/${src}.webp` +
                (maxDpi >= 2
                  ? `, ${process.env.GATSBY_CDN_HOST}/${src}-2x.webp 2x`
                  : '')
              }
            />
            <source
              media="(max-width: 500px)"
              srcSet={`${process.env.GATSBY_CDN_HOST}/${src}-mobile.${type}, ${process.env.GATSBY_CDN_HOST}/${src}-mobile-2x.${type} 2x`}
            />
            <source
              media="(min-width: 500px)"
              srcSet={
                `${process.env.GATSBY_CDN_HOST}/${src}.${type}` +
                (maxDpi >= 2
                  ? `, ${process.env.GATSBY_CDN_HOST}/${src}-2x.${type} 2x`
                  : '')
              }
            />
            {isFullSize ? (
              <ImageStyledFull
                loading={disableLazyLoad ? 'eager' : 'lazy'}
                src={`${process.env.GATSBY_CDN_HOST}/${src}.${type}`}
                alt={alt}
                roundShape={roundShape}
                {...sizeProps}
              />
            ) : (
              <ImageStyled
                loading={disableLazyLoad ? 'eager' : 'lazy'}
                src={`${process.env.GATSBY_CDN_HOST}/${src}.${type}`}
                alt={alt}
                roundShape={roundShape}
                {...sizeProps}
              />
            )}
          </picture>
        </LazyLoadComponent>
      </ImageWrapper>
    </>
  )
}

AdaptiveImage.displayName = 'AdaptiveImage'
export default AdaptiveImage
