import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { UserInterface, AdaptiveImage, Svg } from '../..'
import 'slick-carousel/slick/slick.css'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

const SliderCardArrowStyled = styled(({ ...props }) => <div {...props} />)`
  cursor: pointer;
  ${({ navigation }) =>
    navigation === 'previous' && `transform: rotate( -180deg)`};
  .svg--color-primary {
    transition: 0.3s;
  }
  &:hover {
    .svg--color-primary {
      fill: ${UserInterface.colors.middleGrey};
    }
  }
`

/**
 * Style
 */
const SliderCardArrow = ({ onClick, navigation }) => {
  return (
    <SliderCardArrowStyled navigation={navigation} onClick={onClick}>
      <Svg src="common/icon/unicolor/arrow" width="2.5rem" color="lightGrey" />
    </SliderCardArrowStyled>
  )
}

const SlideComponent = styled(props => <Slider {...props} />)`
  ${({ $withArrows, $background }) =>
    !$withArrows &&
    `
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    width: 100px;
    height: 100%;
    
    background-image: ${
      $background === 'extra-light-blue'
        ? `linear-gradient(to right, rgba(249, 251, 255, 1), rgba(249, 251, 255, 0));`
        : `linear-gradient(to right, rgb(255, 255, 255, 49.5), rgba(245, 245, 245, 0));`
    }
    



    z-index: 19;
    pointer-events: none;

    @media screen and (min-width: ${
      UserInterface.breakpoints.extraSmallMobile
    }) {
      width: 200px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
      width: 470px;
      left: -13px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      width: 210px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -30px;
    width: 130px;
    height: 100%;
    background: linear-gradient(
      to left,
      rgb(255, 255, 255, 49.5),
      rgba(245, 245, 245, 0)
    );

    background-image: ${
      $background === 'extra-light-blue'
        ? `linear-gradient(to left, rgba(249, 251, 255, 1), rgba(249, 251, 255, 0));`
        : `linear-gradient(to left, rgb(255, 255, 255, 49.5), rgba(245, 245, 245, 0));`
    }

    z-index: 19;
    pointer-events: none;

    @media screen and (min-width: ${
      UserInterface.breakpoints.extraSmallMobile
    }) {
      width: 200px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
      width: 470px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      width: 210px;
    }
  }
  
  
  `}
`

const SliderCardDots = styled.ul`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`
const SliderCardDot = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${UserInterface.colors.lightGrey};
  display: block;
  margin: 10px;
  transition: 0.3s;

  .slick-active & {
    background-color: ${UserInterface.colors.blue};
  }
`
const SliderCardWrapper = styled.div`
  width: 100%;

  .image {
    min-width: 30px;
    height: 40px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    .image {
      min-width: 35px;
      height: 50px;
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    .image {
      min-width: 40px;
      height: 60px;
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.largeTable}) {
    .image {
      min-width: 75px;
      height: 60px;
    }
  }

  .slick-slider {
    display: flex;
    align-items: center;

    @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
      flex-direction: column-reverse;
      .slick-list {
        width: 60vw;
      }
    }
    @media screen and (max-width: ${UserInterface.breakpoints.smallMobile}) {
      flex-direction: column-reverse;
      .slick-list {
        width: 90vw;
      }
    }
  }
`
const SliderCardYado = styled(props => <AdaptiveImage {...props} />)`
  max-width: 100%;
  position: absolute;
  bottom: -1.765rem;
  right: calc(100% - 3rem);
  height: 20.5rem;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
    height: 2rem;
  }
`

/**
 * Component
 */
const SliderLogo = ({
  children,
  yado,
  slidesToShow = 3,
  changeInMobile = true,
  visibleByDefault = false,
  withArrows = true,
  autoplay = false,
  withDots = true,
  background = 'default',
}) => {
  const settings = {
    infinite: true,
    slidesToShow: slidesToShow,
    focusOnSelect: true,
    centerPadding: '0',
    nextArrow: <SliderCardArrow />,
    prevArrow: <SliderCardArrow navigation="previous" />,
    arrows: withArrows,
    autoplay: autoplay,
    // autoplaySpeed: 1,
    speed: 3000,
    cssEase: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
    className: 'center',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          arrows: false,
          dots: withDots,
          appendDots: dots => <SliderCardDots>{dots}</SliderCardDots>,
          customPaging: () => {
            return (
              <div>
                <SliderCardDot />
              </div>
            )
          },
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: withDots,
          appendDots: dots => <SliderCardDots>{dots}</SliderCardDots>,
          customPaging: () => {
            return (
              <div>
                <SliderCardDot />
              </div>
            )
          },
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: withDots,
          appendDots: dots => <SliderCardDots>{dots}</SliderCardDots>,
          customPaging: () => {
            return (
              <div>
                <SliderCardDot />
              </div>
            )
          },
        },
      },
    ],
  }
  return (
    <LazyLoadComponent threshold={400} visibleByDefault={visibleByDefault}>
      <SliderCardWrapper changeInMobile={changeInMobile}>
        <SlideComponent
          $withArrows={withArrows}
          $background={background}
          {...settings}
        >
          {children}
        </SlideComponent>
        {yado && (
          <SliderCardYado src={`common/yado/${yado.image}`} alt={yado.alt} />
        )}
      </SliderCardWrapper>
    </LazyLoadComponent>
  )
}

export default SliderLogo
