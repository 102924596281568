import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { NavigationSubscription, UserInterface } from '../index'
import { ThemeProvider } from 'styled-components'

/**
 * Component
 */
const LayoutSubscription = ({
  backLink = '',
  children,
  lang,
  meta,
  title,
  translations,
  canonical,
}) => {
  useEffect(() => {
    window.dataLayer = window?.dataLayer || []
    window.dataLayer.push({
      'page-viewed': meta?.title,
    })

    // Load rudderanalytics script if doesn't exists (tracking)
    const hasRudderScript = document.getElementById('rudder')

    if (!hasRudderScript) {
      setTimeout(() => {
        const rudder = document.createElement('script')
        rudder.innerHTML = `
        var script = document.createElement('script');
        script.setAttribute('src', 'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js');
        document.head.append(script);
        rudderanalytics=window.rudderanalytics=[];for(var methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],i=0;i<methods.length;i++){var method=methods[i];rudderanalytics[method]=function(a){return function(){rudderanalytics.push([a].concat(Array.prototype.slice.call(arguments)))}}(method)}rudderanalytics.load("${process.env.GATSBY_RUDDERSTACK_KEY}","${process.env.GATSBY_RUDDERSTACK_HOST}"),rudderanalytics.reset();
        // window?.branch is injected in Netlify (eg: https://app.netlify.com/sites/assoconnect-showcase-en-us/settings/deploys#post-processing)
        if(window?.branch) {
          rudderanalytics.identify(rudderanalytics.getAnonymousId(), {
            branch: window?.branch,
          })
        }
      `
        rudder.id = 'rudder'
        document.body.appendChild(rudder)

        window?.rudderanalytics.page()
      }, 1500)
    }
    if (hasRudderScript) {
      window?.rudderanalytics.page()
    }
  }, [meta?.title])

  const url = canonical
    ? process.env.GATSBY_SITE_URL + canonical
    : process.env.GATSBY_SITE_URL

  return (
    <>
      <Helmet defer={false}>
        <html lang={lang} />
        <meta charSet="utf-8" />
        <link rel="canonical" href={url} />
        <title>{meta.title}</title>
        <link
          rel="preconnect"
          href="https://web-assoconnect-frc-prod-cdn-endpoint-showcase.azureedge.net"
        />
        <link rel="preconnect" href="https://cdn.rudderlabs.com" />
      </Helmet>
      <UserInterface.GlobalStyle />
      <ThemeProvider
        theme={{
          breakpoints: [UserInterface.breakpoints.mobile],
        }}
      >
        <NavigationSubscription
          backLink={backLink}
          title={title}
          translations={translations}
        />
        {children}
      </ThemeProvider>
    </>
  )
}

export default LayoutSubscription
