import React, { FC } from 'react'
import { UserInterface } from '.'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { ISvg, ISvgStyledProps } from './type'

/**
 * Style
 */
const SvgWrapper = styled.div`
  /* Hack whitespace */
  font-size: 0;
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: center;
  }
`
const SvgStyled = styled(({ ...props }: ISvgStyledProps) => (
  <ReactSVG {...props} />
))`
  svg {
    display: inline-block;
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    .svg--color-primary {
      fill: ${({ color }) => (color ? UserInterface.colors[color] : 'initial')};
    }
    .svg--color-secondary {
      fill: ${({ $colorSecondary }) =>
        UserInterface.colors[$colorSecondary ?? 'turquoise']};
    }
  }
`

/**
 * Component
 */
const Svg: FC<ISvg> = ({
  className = undefined,
  color = 'middleGrey',
  colorSecondary = 'turquoise',
  height = '',
  src,
  visibleByDefault = false,
  width = '',
  alt = '',
}) => {
  let widthCalc = width
  let heightCalc = height
  if (width !== '' && height === '') {
    heightCalc = width
  } else if (width === '' && height !== '') {
    widthCalc = height
  }
  return (
    <LazyLoadComponent threshold={200} visibleByDefault={visibleByDefault}>
      <SvgWrapper className={className}>
        <SvgStyled
          alt={alt}
          src={`${process.env.GATSBY_CDN_HOST}/${src}.svg`}
          color={color}
          $colorSecondary={colorSecondary}
          width={widthCalc}
          height={heightCalc}
          loading={() => (
            <div style={{ height: heightCalc, width: widthCalc }} />
          )}
        />
      </SvgWrapper>
    </LazyLoadComponent>
  )
}
Svg.displayName = 'Svg' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default Svg
