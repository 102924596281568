import React, { createElement, FC } from 'react'
import { UserInterface } from '..'
import styled from 'styled-components'
import classNames from 'classnames'
import { IBigTitle, IBigTitleStyled } from './type'

/**
 * Style
 */
const BigTitleStyled = styled(
  ({ tag, children, $minHeight, ...props }: IBigTitleStyled) =>
    createElement(tag, props, children, $minHeight)
)`
  font-family: ${({ font }) => UserInterface.fonts.family[font]};
  font-weight: ${UserInterface.fonts.weight.bold};
  font-size: 2.4rem;
  line-height: 3.175rem;
  text-align: ${({ align }) => align};
  color: ${({ color }) => UserInterface.colors[color]};
  min-height: ${({ $minHeight }) => $minHeight};
  ${({ display }) => (display ? `display: ${display}` : ``)};

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.smallMobile}) {
    font-size: 1.75rem;
    line-height: 2.5rem;
    text-align: center;
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: center;
  }
`

/**
 * Component
 */
const BigTitle: FC<IBigTitle> = ({
  align = 'center',
  children,
  className,
  color = 'white',
  font = 'poppins',
  tag = 'h2',
  display,
  minHeight = 'none',
  id,
}) => (
  <BigTitleStyled
    tag={tag}
    align={align}
    color={color}
    font={font}
    display={display}
    $minHeight={minHeight}
    className={classNames('title--big', className)}
    id={id}
  >
    {children}
  </BigTitleStyled>
)
BigTitle.displayName = 'BigTitle' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default BigTitle
