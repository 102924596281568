export const globalItems = [
  {
    href: '/storybook/colors',
    title: 'COLORS',
  },
]

export const items = [
  {
    href: '/storybook/adaptative-image',
    title: 'AdaptativeImage',
  },
  {
    href: '/storybook/animation-number',
    title: 'AnimationNumber',
  },
  {
    href: '/storybook/arrow',
    title: 'Arrow',
  },
  {
    href: '/storybook/big-title',
    title: 'BigTitle',
  },
  {
    href: '/storybook/box',
    title: 'Box',
  },
  {
    href: '/storybook/bullet-list',
    title: 'BulletList',
  },
  {
    href: '/storybook/button-link',
    title: 'ButtonLink',
  },
  {
    href: '/storybook/button-text',
    title: 'ButtonText',
  },
  {
    href: '/storybook/calendly',
    title: 'Calendly',
  },
  {
    href: '/storybook/card',
    title: 'Card',
  },
  {
    href: '/storybook/cards-multi',
    title: 'CardsMulti',
  },
  {
    href: '/storybook/check-list',
    title: 'CheckList',
  },
  {
    href: '/storybook/header-big',
    title: 'HeaderBig',
  },
  {
    href: '/storybook/header-home',
    title: 'HeaderHome',
  },
  {
    href: '/storybook/header-picture',
    title: 'HeaderPicture',
  },
  {
    href: '/storybook/header-small',
    title: 'HeaderSmall',
  },
  {
    href: '/storybook/header-video',
    title: 'HeaderVideo',
  },
  {
    href: '/storybook/input-basic',
    title: 'InputBasic',
  },
  {
    href: '/storybook/input-button',
    title: 'InputButton',
  },
  {
    href: '/storybook/link',
    title: 'Link',
  },
  {
    href: '/storybook/logo-carrousel',
    title: 'LogoCarrousel',
  },
  {
    href: '/storybook/middle-title',
    title: 'MiddleTitle',
  },
  {
    href: '/storybook/outgrow',
    title: 'Outgrow',
  },
  {
    href: '/storybook/section',
    title: 'Section',
  },
  {
    href: '/storybook/seo',
    title: 'Seo',
  },
  {
    href: '/storybook/six-cards',
    title: 'SixCards',
  },
  {
    href: '/storybook/slider-card',
    title: 'SliderCard',
  },
  {
    href: '/storybook/slider-feature',
    title: 'SliderFeature',
  },
  {
    href: '/storybook/slider-list',
    title: 'SliderList',
  },
  {
    href: '/storybook/slider-testimony',
    title: 'SliderTestimony',
  },
  {
    href: '/storybook/small-title',
    title: 'SmallTitle',
  },
  {
    href: '/storybook/space',
    title: 'Space',
  },
  {
    href: '/storybook/subtitle',
    title: 'Subtitle',
  },
  {
    href: '/storybook/svg',
    title: 'Svg',
  },
  {
    href: '/storybook/table',
    title: 'Table',
  },
  {
    href: '/storybook/team',
    title: 'Team',
  },
  {
    href: '/storybook/testimonial',
    title: 'Testimonial',
  },
  {
    href: '/storybook/text',
    title: 'Text',
  },
  {
    href: '/storybook/text-box',
    title: 'TextBox',
  },
  {
    href: '/storybook/video',
    title: 'Video',
  },
  {
    href: '/storybook/video-button',
    title: 'VideoButton',
  },
  {
    href: '/storybook/video-image',
    title: 'VideoImage',
  },
  {
    href: '/storybook/video-placeholder',
    title: 'VideoPlaceholder',
  },
  {
    href: '/storybook/wheel',
    title: 'Wheel',
  },
]
