import React, { FC } from 'react'
import styled from 'styled-components'
import { EBreakpoint } from 'src/styles/type'

export const ListCtaBlockStyled = styled.aside`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${EBreakpoint.SMALL_MOBILE}) {
    display: none;
  }
`
const ListCtaBlock: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <ListCtaBlockStyled>{children}</ListCtaBlockStyled>
}

export default ListCtaBlock
