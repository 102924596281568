import React from 'react'
import { globalHistory } from '@gatsbyjs/reach-router'
import 'src/fonts/stylesheets.css'

import { AppWrapper } from './src/components'

export const wrapRootElement = ({ element }) => {
  return <AppWrapper content={element} />
}

/*
APPEND ONCLICK SCRIPT (wistia => video)
Callback to append scripts
**/

const loadedScriptsOnClick = 'loadedScriptsOnClick'

const appendThirdPartyScriptsOnClick = () => {
  // Variable set to reference as a check that callback ran
  sessionStorage.setItem(loadedScriptsOnClick, 'true')
}

/*
APPEND ONSCROLL SCRIPT 
Callback to append scripts
**/

const appendThirdPartyScriptsOnScroll = () => {
  // Hubspot cta script load => currently load in Layouts to avoid undefined error => fix this
  // appendCtaScript()

  if (!document.getElementById('gtm')) {
    appendGoogleTagManagerScript(`
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}');
  `)
  }

  // Variable set to reference as a check that callback ran
  sessionStorage.setItem(loadedScriptsOnScroll, 'true')
}

/*
ONSCROLL SCRIPTS
**/

const appendGoogleTagManagerScript = scriptInnerHTML => {
  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('id', 'gtm')
  scriptElement.setAttribute('defer', '')
  scriptElement.innerHTML = scriptInnerHTML
  document.head.appendChild(scriptElement)
}

const loadedScriptsOnScroll = 'loadedScriptsOnScroll'

/*
LOAD SCRIPT ON RENDER
**/

export const onInitialClientRender = () => {
  if (window !== undefined) {
    window?.addEventListener(
      'scroll',
      appendThirdPartyScriptsOnScroll,
      appendScriptsOnScrollOptions,
      { passive: true }
    )

    window?.addEventListener(
      'click',
      appendThirdPartyScriptsOnClick,
      appendScriptsOnScrollOptions,
      { passive: true }
    )
  }

  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete()
}
const appendScriptsOnScrollOptions = { once: true }

/*
CHECK SCRIPT LOADED ON ROUTE UPDATE
**/

export const onRouteUpdate = ({ prevLocation }) => {
  const checkLoadedScriptsOnScroll = sessionStorage.getItem(
    loadedScriptsOnScroll
  )
  const checkLoadedScriptsOnClick = sessionStorage.getItem(loadedScriptsOnClick)

  // If first navigation update and user didn't scroll, append
  // scripts
  if (
    prevLocation &&
    (!checkLoadedScriptsOnScroll || !checkLoadedScriptsOnClick)
  ) {
    // Prevent appending scripts on scroll
    window?.removeEventListener(
      'scroll',
      appendThirdPartyScriptsOnScroll,
      appendScriptsOnScrollOptions,
      { passive: true }
    )
    window?.removeEventListener(
      'click',
      appendThirdPartyScriptsOnClick,
      appendScriptsOnScrollOptions,
      { passive: true }
    )
    appendThirdPartyScriptsOnScroll()
    appendThirdPartyScriptsOnClick()
  }
}
