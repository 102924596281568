import React, { useEffect } from 'react'

const HubspotContactForm = ({ portalId, formId }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener(
      'load',
      () => {
        if (window?.hbspt) {
          window?.hbspt.forms.create({
            portalId,
            formId,
            target: '#react-hubspot-form',
          })
        }
      },
      { passive: true }
    )
  }, [formId, portalId])

  return (
    <div>
      <div id="react-hubspot-form"></div>
    </div>
  )
}

export default HubspotContactForm
