import React, { createElement, FC } from 'react'
import { UserInterface } from '..'
import styled from 'styled-components'
import classNames from 'classnames'
import { ISubtitle } from './type'
import { ETextAlign } from '../type'

/**
 * Style
 */

const SubtitleStyled = styled(({ tag, children, ...rest }: ISubtitle) =>
  createElement(tag, rest, children)
)`
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: ${UserInterface.fonts.weight.light};
  font-size: 1.375rem;
  line-height: 2.1875rem;
  letter-spacing: 0.5px;
  text-align: ${({ align }) => align};
  color: ${({ color }) => (color ? UserInterface.colors[color] : 'initial')};

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    font-size: 0.95rem;
    text-align: center;
    line-height: 1.412rem;
  }
`

/**
 * Component
 */
const Subtitle: FC<ISubtitle> = ({
  align = ETextAlign.CENTER,
  children,
  className,
  color = 'lightGrey',
  tag = 'h5',
}) => (
  <SubtitleStyled
    tag={tag}
    align={align}
    color={color}
    className={classNames(className)}
  >
    {children}
  </SubtitleStyled>
)
Subtitle.displayName = 'Subtitle' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default Subtitle
