import React, { createElement, FC } from 'react'
import { UserInterface } from '..'
import styled from 'styled-components'
import classNames from 'classnames'
import { IExtraBigTitle, IExtraBigTitleStyled } from './type'

/**
 * Style
 */
const ExtraBigTitleStyled = styled(
  ({ tag, children, ...props }: IExtraBigTitleStyled) =>
    createElement(tag, props, children)
)`
  font-family: ${({ font }) => UserInterface.fonts.family[font]};
  font-weight: ${UserInterface.fonts.weight.bold};
  font-size: 3rem;
  line-height: 4.2rem;
  text-align: ${({ align }) => align};
  color: ${({ color }) => UserInterface.colors[color]};
  min-height: ${({ $minHeight }) => $minHeight};
  ${({ display }) => (display ? `display: ${display}` : ``)};

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.smallMobile}) {
    font-size: 1.75rem;
    line-height: 2.5rem;
    text-align: center;
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: center;
  }
`

/**
 * Component
 */
const ExtraBigTitle: FC<IExtraBigTitle> = ({
  align = 'center',
  children,
  className,
  color = 'white',
  font = 'poppins',
  tag = 'h2',
  display,
  minHeight = 'none',
  id,
}) => (
  <ExtraBigTitleStyled
    tag={tag}
    align={align}
    color={color}
    font={font}
    display={display}
    $minHeight={minHeight}
    className={classNames('title--big', className)}
    id={id}
  >
    {children}
  </ExtraBigTitleStyled>
)
ExtraBigTitle.displayName = 'ExtraBigTitle' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default ExtraBigTitle
