import React, { useState } from 'react'
import { UserInterface, Link, Box, Section } from '..'
import styled, { ThemeProvider } from 'styled-components'
import * as Data from './data'
import { globalHistory as history } from '@gatsbyjs/reach-router'
import { Helmet } from 'react-helmet'
import { v4 as uuid } from 'uuid'

export const Background = styled.div`
  display: none;
  width: 220px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 222;
  background-color: #333333;
  @media (min-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
  }
`

export const Menu = styled.nav`
  font-family: ${UserInterface.fonts.family.roboto};
  color: ${UserInterface.colors.white};
  display: none;
  width: 220px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  font-size: 1rem;
  z-index: 333;
  word-wrap: break-word;
  @media (min-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
  }
`

export const Item = styled(({ active, ...props }) => (
  <Link active={active} {...props} />
))`
  color: ${UserInterface.colors.white};
  display: block;
  ${({ active }) => active && `font-weight: bold;`}
  padding: 5px 10px;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    color: ${UserInterface.colors.white};
    background-color: rgba(255, 255, 255, 0.1);
  }
`

export const Main = styled.main`
  font-family: Arial, sans-serif;
  @media (min-width: ${UserInterface.breakpoints.mobile}) {
    margin-left: 220px;
  }
`

const LayoutStorybook = ({ children }) => {
  const [itemOpened, setItemOpened] = useState(null)

  const itemIsOpened = itemHref =>
    itemOpened === itemHref ||
    (itemOpened === null && history.location.pathname.search(itemHref) !== -1)

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Storybook showcase</title>
      </Helmet>
      <UserInterface.GlobalStyle />
      <ThemeProvider
        theme={{
          breakpoints: [UserInterface.breakpoints.mobile],
        }}
      >
        <Background />
        <Menu
          role="navigation"
          onMouseLeave={() => {
            setItemOpened(null)
          }}
        >
          {Data.globalItems.map(item => (
            <Item
              key={uuid()}
              href={item.href}
              onClick={() => {
                setItemOpened(item.href)
              }}
              active={itemIsOpened(item.href)}
            >
              {item.title}
            </Item>
          ))}
          <hr />
          {Data.items.map(item => (
            <Item
              key={uuid()}
              href={item.href}
              onClick={() => {
                setItemOpened(item.href)
              }}
              active={itemIsOpened(item.href)}
            >
              {item.title}
            </Item>
          ))}
        </Menu>
        <Main>
          <Section background={false}>
            <Box>{children}</Box>
          </Section>
        </Main>
      </ThemeProvider>
    </>
  )
}

export default LayoutStorybook
