import React, { FC } from 'react'
import styled from 'styled-components'

import { IEmoji } from './type'

/**
 * Style
 */
const EmojiStyle = styled(props => <span {...props} />)`
  bottom: 2px;
  position: relative;
`

/**
 * Component
 */
const Emoji: FC<IEmoji> = ({ children }) => <EmojiStyle>{children}</EmojiStyle>

export default Emoji
