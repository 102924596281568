import React from 'react'
import { login, subscription } from './links'
import { ESubscription } from './type'

const defaultNavigationUS = {
  navigationButtons: [login, subscription(ESubscription.SERENITY_US)],
  items: [
    {
      text: 'What we do',
      href: '/en-us/nonprofit-software/',
      boxes: [
        {
          children: [
            {
              text: (
                <>
                  <b>Core management tools</b>
                </>
              ),
              href: '/en-us/features/online-software/',
            },
            {
              text: 'CRM',
              icon: 'common/icon/multicolor/members',
              href: '/en-us/features/crm-nonprofit/',
            },
            {
              text: 'Accounting',
              icon: 'common/icon/multicolor/accounting',
              href: '/en-us/features/nonprofit-accounting/',
            },
            {
              text: 'Emailing',
              icon: 'common/icon/multicolor/emailing',
              href: '/en-us/features/nonprofit-communication-tools/',
            },
            {
              text: 'Website',
              icon: 'common/icon/multicolor/responsive',
              href: '/en-us/features/nonprofit-communication-tools/',
            },
          ],
        },
        {
          children: [
            {
              text: (
                <>
                  <b>Payment Solutions</b>
                </>
              ),
              href: '/en-us/features/online-payment-system-nonprofits/',
            },
            {
              text: 'Memberships',
              icon: 'common/icon/multicolor/membership',
              href: '/en-us/features/nonprofit-membership-software/',
            },
            {
              text: 'Donations',
              icon: 'common/icon/multicolor/donations',
              href: '/en-us/features/donation-software/',
            },
            {
              text: 'Events',
              icon: 'common/icon/multicolor/ticketing',
              href: '/en-us/features/nonprofit-events/',
            },
            {
              text: 'Online shop',
              icon: 'common/icon/multicolor/shop-driver',
              href: '/en-us/features/nonprofit-online-store/',
            },
          ],
        },
      ],

      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            Discover all of our features
          </>
        ),
        href: '/en-us/nonprofit-software/',
      },
      width: 440,
    },
    {
      text: 'Who we serve',
      href: '/en-us/software-for-nonprofits-by-sector/',
      boxes: [
        {
          children: [
            {
              text: '501(c)(3) organizations',
              icon: 'common/icon/multicolor/members',
              href: '/en-us/nonprofit-software/',
            },
            {
              text: 'Clubs',
              icon: 'common/icon/multicolor/sport',
              href: '/en-us/nonprofit/club-management-software/',
            },
            {
              text: 'Associations',
              icon: 'common/icon/multicolor/political',
              href: '/en-us/nonprofit/association-management-software/',
            },
            {
              text: 'Churches & Religious Organizations',
              icon: 'common/icon/multicolor/religious',
              href: '/en-us/nonprofit/church-management-software/',
            },
            {
              text: 'Networks',
              icon: 'common/icon/multicolor/connection',
              href: '/en-us/networks/',
            },
            {
              text: 'Students, Colleges & Universities',
              icon: 'common/icon/multicolor/student',
              href: '/en-us/nonprofit/software-for-colleges-and-universities/',
            },
            {
              text: 'Professional Organizations',
              icon: 'common/icon/multicolor/pro',
              href: '/en-us/nonprofit/professional-association-software/',
            },
          ],
        },
      ],
      width: 340,
    },
    {
      text: 'Pricing',
      href: '/en-us/pricing/',
    },
    {
      text: 'Resources',
      href: '/en-us/resources/',
      boxes: [
        {
          title: 'About Springly',
          children: [
            {
              text: 'Our tutorials',
              arrow: true,
              href: '/en-us/demo/',
            },
            {
              text: 'Board Presentations',
              arrow: true,
              href: '/en-us/resources/board-documents/',
            },
            {
              text: 'Compare Your Software',
              arrow: true,
              href: '/en-us/resources/compare/',
            },
            {
              text: 'Partnerships',
              arrow: true,
              href: '/en-us/partnerships/techsoup/',
            },

            {
              text: 'Product updates',
              arrow: true,
              href: 'https://product-evolution.springly.org/page/2020738-product-updates',
            },
          ],
        },
        {
          title: 'Other resources',
          children: [
            {
              text: 'Blog',
              arrow: true,
              href: '/en-us/blog/',
              nofollow: false,
            },
            {
              text: 'Newsletter',
              arrow: true,
              href: '/en-us/resources/newsletter/',
            },
            {
              text: 'eBooks',
              arrow: true,
              href: '/en-us/resources/ebooks/',
            },
            {
              text: 'Nonprofit Management Circle',
              arrow: true,
              href: '/en-us/nonprofit-management-circle/',
            },
            {
              text: 'Nonprofit Tips',
              arrow: true,
              href: '/en-us/resources/nonprofit-tips/',
            },
          ],
        },
      ],
      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            NEW EBOOK: Nonprofit Networks
          </>
        ),
        href: '/en-us/resources/ebooks/networks/',
      },
      width: 520,
    },
  ],
}

export default defaultNavigationUS
