import React, { FC } from 'react'
import styled from 'styled-components'
import { UserInterface } from '..'

/**
 * Style
 */

const WisepopContainer = styled.div`
  margin-top: 0.8rem;
  margin-bottom: 1.2rem;

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`

const WisepopBell = styled.svg`
  margin: O.4rem;

  path {
    stroke: black;
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    path {
      stroke: white;
    }
  }
`

/**
 * Component
 */
const Wisepop: FC = () => (
  <WisepopContainer className="wisp">
    <WisepopBell
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      width="22"
      height="22"
      viewBox="0 0 24 24"
      stroke="black"
      xmlns="<http://www.w3.org/2000/svg>"
    >
      <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
    </WisepopBell>
  </WisepopContainer>
)

export default Wisepop
