import React, { Children } from 'react'
import styled, { css } from 'styled-components'
import { UserInterface, Svg } from '../../..'
import getDisplayName from 'react-display-name'
import classNames from 'classnames/bind'

/**
 * Style
 */
const ArrowStyled = styled.span`
  width: 200px;
  font-family: ${UserInterface.fonts.family.caveat};
  color: ${UserInterface.colors.yellow};
  display: inline-block;
  font-size: 34px;
  line-height: 3rem;
  position: absolute;

  @media screen and (min-width: 600px) {
    font-size: 40px;
  }

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    width: 290px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    width: 290px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.largeTable}) {
    width: 290px;
  }
`
const positionRight = css``
const positionLeft = css``
const directionLeft = css`
  transform: scaleX(-1);
`
const SvgStyled = styled(props => <Svg {...props} />)`
  left: ${({ position }) => (position === 'left' ? '72px' : '')};
  right: ${({ position }) => (position === 'right' ? '91px' : '')};

  position: absolute;

  ${({ position }) => position === 'left' && positionLeft};
  ${({ position }) => position === 'right' && positionRight};
  ${({ direction }) => direction === 'left' && directionLeft}
`

const ArrowContainer = styled.div`
  position: relative;

  top: ${({ position }) => (position === 'left' ? '-20px' : '-40px')};
  left: ${({ position }) => (position === 'left' ? '10px' : '')};
  right: ${({ position }) => (position === 'right' ? '30px' : '')};

  @media screen and (min-width: ${UserInterface.breakpoints.extraSmallMobile}) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '10px' : '-40px')};
    left: ${({ position }) => (position === 'left' ? '30px' : '')};
    right: ${({ position }) => (position === 'right' ? '35px' : '')};
  }

  @media screen and (min-width: 650px) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '10px' : '-40px')};
    left: ${({ position }) => (position === 'left' ? '30px' : '')};
    right: ${({ position }) => (position === 'right' ? '-50px' : '')};
  }

  @media screen and (min-width: 750px) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '10px' : '-20px')};
    left: ${({ position }) => (position === 'left' ? '80px' : '')};
    right: ${({ position }) => (position === 'right' ? '-100px' : '')};
  }

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '10px' : '-20px')};
    left: ${({ position }) => (position === 'left' ? '140px' : '')};
    right: ${({ position }) => (position === 'right' ? '-130px' : '')};
  }

  @media screen and (min-width: 1050px) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '10px' : '-20px')};
    left: ${({ position }) => (position === 'left' ? '140px' : '')};
    right: ${({ position }) => (position === 'right' ? '-180px' : '')};
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '-60px' : '-95px')};
    left: ${({ position }) => (position === 'left' ? '-135px' : '')};
    right: ${({ position }) => (position === 'right' ? '50px' : '')};
  }

  @media screen and (min-width: ${UserInterface.breakpoints.largeTable}) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '-60px' : '-95px')};
    left: ${({ position }) => (position === 'left' ? '-135px' : '')};
    right: ${({ position }) => (position === 'right' ? '-5px' : '')};
  }

  @media screen and (min-width: ${UserInterface.breakpoints.table}) {
    width: 400px;
    top: ${({ position }) => (position === 'left' ? '-60px' : '-95px')};
    left: ${({ position }) => (position === 'left' ? '-135px' : '')};
    right: ${({ position }) => (position === 'right' ? '-40px' : '')};
  }
`

/**
 * Component
 */
const ArrowOffers = ({
  align = 'left',
  arrowColor = 'yellow',
  arrowSvg = 'curve-arrow',
  children,
  color,
  direction = 'right',
  position = 'left',
  className,
}) => (
  <ArrowContainer position={position}>
    <ArrowStyled className={classNames(className)} align={align} color={color}>
      {color === 'white'
        ? Children.map(children, child => {
            if (child.type) {
              switch (getDisplayName(child.type)) {
                case 'MiddleTitle':
                case 'SmallTitle':
                case 'Text':
                case 'BulletList':
                case 'span':
                  return React.cloneElement(child, {
                    color: color,
                    left: child.props.left ? '-60px' : '',
                  })
                case 'Svg':
                  return React.cloneElement(child, {
                    left: child.props.left ? '-60px' : '',
                    color: child.props.color || color,
                    align: child.props.align || align,
                  })
                default:
                  return child
              }
            } else return child
          })
        : Children.map(children, child => {
            if (child.type) {
              switch (getDisplayName(child.type)) {
                case 'MiddleTitle':
                case 'SmallTitle':
                case 'Text':
                case 'BulletList':
                case 'Svg':
                  return React.cloneElement(child, {
                    align: child.props.align || align,
                  })
                default:
                  return child
              }
            } else return child
          })}

      <SvgStyled
        src={`components/arrow/${arrowSvg}`}
        color={arrowColor}
        width="60px"
        position={position}
        direction={direction}
      />
    </ArrowStyled>
  </ArrowContainer>
)

export default ArrowOffers
