import { useState } from 'react'
import * as Type from './type'

// Get the previous card id: if the current card id is 0, the previous card id is the last card id
const getPreviousCardId = (currentCardId: number, cardAmount: number) =>
  (currentCardId - 1) % cardAmount === -1
    ? cardAmount - 1
    : (currentCardId - 1) % cardAmount

// Get the next card id: if the current card id is the last card id, the next card id is 0
const getNextCardId = (currentCardId: number, cardAmount: number) =>
  (currentCardId + 1) % cardAmount === -1 ? 0 : (currentCardId + 1) % cardAmount

const useCards = (
  cardAmount: number
): {
  cardsStatus: Type.CardsStatus
  isSwiping: boolean
  displayNextCard: () => void
  displayPreviousCard: () => void
  displayCardFromId: (id: number) => void
} => {
  const [cardsStatus, setCardsStatus] = useState({
    currentCardId: 0,
    nextCardId: getNextCardId(0, cardAmount),
    previousCardId: getPreviousCardId(0, cardAmount),
  })

  const [isSwiping, setIsSwiping] = useState(false)

  const displayCardFromId = (id: number) => {
    setCardsStatus(prevStatus => {
      return {
        ...prevStatus,
        currentCardId: id,
        nextCardId: getNextCardId(id, cardAmount),
        previousCardId: getPreviousCardId(id, cardAmount),
      }
    })
  }

  const displayNextCard = () => {
    setIsSwiping(true)

    setTimeout(() => {
      setIsSwiping(false)

      setCardsStatus(prevStatus => {
        return {
          ...prevStatus,
          currentCardId: prevStatus.nextCardId,
          nextCardId: getNextCardId(prevStatus.nextCardId, cardAmount),
          previousCardId: getPreviousCardId(prevStatus.nextCardId, cardAmount),
        }
      })
    }, 300)
  }

  const displayPreviousCard = () => {
    setIsSwiping(true)

    setTimeout(() => {
      setIsSwiping(false)

      setCardsStatus(prevStatus => {
        return {
          ...prevStatus,
          currentCardId: prevStatus.previousCardId,
          nextCardId: getNextCardId(prevStatus.previousCardId, cardAmount),
          previousCardId: getPreviousCardId(
            prevStatus.previousCardId,
            cardAmount
          ),
        }
      })
    }, 300)
  }
  return {
    displayCardFromId,
    displayNextCard,
    displayPreviousCard,
    cardsStatus,
    isSwiping,
  }
}

export default useCards
