import React, { FC } from 'react'
import styled from 'styled-components'
import { CountUp } from 'src/components'
import { IAnimationNumber } from './type'
import { EBreakpoint, EColor, EFontFamily } from 'src/styles/type'

/**
 * Style
 */

const MainContainer = styled(({ ...props }) => <div {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;

  @media (min-width: ${EBreakpoint.MOBILE}) {
    justify-content: end;
    padding-bottom: 0;
  }
`

const Bubble = styled(({ ...props }) => <div {...props} />)`
  width: 200px;
  height: 200px;
  border-radius: 70% 42% 56% 53% / 72% 47% 62% 41%;
  background-color: ${({ background }) =>
    background === 'blue' ? EColor.INTERMEDIATE_BLUE : EColor.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
`

const NumberComponent = styled(({ ...props }) => <span {...props} />)`
  padding-left: 0.5rem;
  font-family: ${EFontFamily.POPPINS};
  font-size: ${({ $endNumber }) => ($endNumber < 100 ? '6rem' : '4rem')};
  font-weight: 700;
  color: ${({ background }) =>
    background === 'blue' ? EColor.WHITE : EColor.PASTEL_BLUE};

  & > sup {
    font-size: 3rem;
    vertical-align: top;
    top: 23px;
    position: relative;
  }
  & > sub {
    font-size: 0.667rem;
    font-family: ${EFontFamily.ROBOTO};
    font-weight: 300;
  }
`

/**
 * Component
 */
const AnimationNumber: FC<IAnimationNumber> = ({
  startNumber,
  endNumber,
  symbol,
  background = EColor.BLUE,
}) => {
  return (
    <MainContainer>
      <Bubble background={background}>
        <NumberComponent $endNumber={endNumber} background={background}>
          {symbol && symbol === '$' && <sup>{symbol}</sup>}
          <CountUp startNumber={startNumber} endNumber={endNumber} />
          {symbol && symbol !== '$' && <sup>{symbol}</sup>}
        </NumberComponent>
      </Bubble>
    </MainContainer>
  )
}

export default AnimationNumber
