import React, { FC } from 'react'
import styled from 'styled-components'
import { Svg, Link } from '../../..'
import { WrapperBordered, TitleBordered } from '../styled.js'
import { IFavPostsAside } from '../type'
import btoa from 'btoa'
import { EBreakpoint, EFontFamily } from 'src/styles/type'

export const ListItem = styled(props => <Link {...props} />)`
  display: flex;
  margin-bottom: 40px;
  align-items: center !important;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${EBreakpoint.MOBILE}) {
    flex-direction: column;
    align-items: start !important;
  }
`

export const MiniImage = styled(({ ...props }) => <div {...props} />)`
  flex: 0 0 130px;
  @media screen and (max-width: ${EBreakpoint.MOBILE}) {
    flex: 0 0 65px;
    margin: auto;
    width: 100%;
    max-width: 180px;
  }

  background-image: url(${({ image }) => image});
  height: 60px;
  background-size: cover;
  background-position: center;
  width: 164px;
`
export const Name = styled.div`
  flex: 1;
  margin: auto;
  font-size: 14px;
  line-height: 17px;
  color: #939393;
  font-family: ${EFontFamily.ROBOTO};
  font-weight: bolder;
  padding-left: 10px;
  @media screen and (max-width: ${EBreakpoint.MOBILE}) {
    margin-top: 10px;
    max-width: 180px;
    padding-left: 0;
  }
`
export const HeartIcon = styled(props => (
  <Svg
    src="components/blog/icons/blog-fav"
    width="20px"
    alt="fav icon"
    {...props}
  />
))`
  width: 20px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 8px;
`

const FavPostsAside: FC<IFavPostsAside> = ({
  blogPath,
  favoritesPosts,
  favoritesSlugs,
  favoritesSlugsObfuscation,
  favoritesTitle,
}) => {
  // Sort favoritesPosts
  favoritesPosts.sort(
    (a, b) =>
      favoritesSlugs.indexOf(a.node.slug) - favoritesSlugs.indexOf(b.node.slug)
  )

  return (
    <WrapperBordered>
      <TitleBordered>
        {favoritesTitle.prefix}
        <HeartIcon />
        {favoritesTitle.suffix}
      </TitleBordered>
      <div>
        {favoritesPosts.map(({ node: { slug, feature_image, title } }, i) => {
          const link = `${blogPath}/${slug}/`
          return (
            <ListItem
              key={i}
              href={
                Array.isArray(favoritesSlugsObfuscation) &&
                favoritesSlugsObfuscation.includes(slug)
                  ? btoa(link)
                  : link
              }
            >
              <MiniImage image={feature_image.url} />
              <Name>{title}</Name>
            </ListItem>
          )
        })}
      </div>
    </WrapperBordered>
  )
}

export default FavPostsAside
