import { ESubscription } from './type'

export const login = {
  text: 'Se connecter',
  href: 'https://app.assoconnect.com/login',
  theme: `link-yellow`,
}

export const contact = {
  text: 'Prenons contact',
  href: 'https://www.assoconnect.com/reseaux/contact/',
  target: '_self',
  theme: 'link-blue',
}

export const subscription = (type = ESubscription.SERENITE) => ({
  text: 'Commencer gratuitement',
  href: `https://app.assoconnect.com/sign-up/first?subscription=${type}`,
  target: '_self',
  theme: 'link-blue',
})

export const tally = {
  text: 'Je me lance',
  href: 'https://tally.so/r/wMNDDp',
  target: '_self',
  theme: 'link-blue',
}

export const discover = {
  text: 'Découvrir AssoConnect',
  href: 'https://www.assoconnect.com/',
  target: '_self',
  theme: 'link-white',
}
