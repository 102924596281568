import React, { FC } from 'react'
import { ThemeProvider } from 'styled-components'
import { UserInterface } from '../index'
import { IAppWrapper } from './type'

const AppWrapper: FC<IAppWrapper> = ({ content }) => {
  return (
    <ThemeProvider
      theme={{
        breakpoints: [UserInterface.breakpoints.mobile],
        colors: UserInterface.colors,
        shadows: UserInterface.shadows,
        fonts: UserInterface.fonts,
        animations: UserInterface.animations,
      }}
    >
      {content}
    </ThemeProvider>
  )
}

export default AppWrapper
