import { Svg, UserInterface } from '..'
import { css, keyframes } from 'styled-components'

import AdaptiveImage from '../AdaptiveImage'
import React from 'react'
import styled from 'styled-components'

/**
 * Style
 */

const animationVideoButton = keyframes`
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`

const VideoImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:hover .playButton {
    background-color: rgba(223, 223, 223, 0.86);
  }
`

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
`

const animationCall = css`
  animation: ${animationVideoButton} 2.3s ease-in-out infinite;
`

const Button = styled.div`
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.86);
  path {
    fill: ${UserInterface.colors.blue};
  }

  transition: 500ms ease;
  ${({ animationVideoButton }) => animationVideoButton && animationCall}
`

const VideoImageStyled = styled(props => <AdaptiveImage {...props} />)`
  cursor: default;
`

/**
 * Component
 */
const VideoImage = ({ image, onClick, videoId, visibleByDefault = false }) => (
  <VideoImageWrapper className="videoImageWrapper" onClick={onClick}>
    <VideoImageStyled
      src={image.src}
      alt={image.alt}
      type={image.type}
      width={image.width}
      height={image.height}
      visibleByDefault={visibleByDefault}
      disableLazyLoad={true}
    />
    {videoId && (
      <PlayButtonWrapper className="videoImageButton">
        <Button
          animationVideoButton={animationVideoButton}
          className="playButton"
        >
          <Svg
            colorSecondary="blue"
            src="components/video-button/simple-play"
            width="96px"
            color="white"
          />
        </Button>
      </PlayButtonWrapper>
    )}
  </VideoImageWrapper>
)

VideoImage.displayName = 'VideoImage'
export default VideoImage
