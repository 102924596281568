import React, { Children } from 'react'
import { UserInterface, Flex } from '../'
import styled, { css } from 'styled-components'
import classNames from 'classnames/bind'

/**
 * Style
 */

const getColor = (color, withGradient) => {
  switch (color) {
    case 'blue':
      return withGradient
        ? `linear-gradient(to bottom, ${UserInterface.colors.mediumBlue}, ${UserInterface.colors.blue} 11.765rem)`
        : UserInterface.colors.blue

    case 'light-blue':
      return withGradient
        ? `linear-gradient(to bottom, ${UserInterface.colors.clearBlue}, ${UserInterface.colors.white} 11.765rem)`
        : UserInterface.colors.clearBlue

    case 'extra-light-blue':
      return UserInterface.colors.extraClearBlue
    case String(color).search('#') !== -1:
      return color
    default:
      return UserInterface.colors[color]
  }
}
const colorMode = css`
  position: relative;
  background: ${({ background, withGradient }) =>
    getColor(background.color, withGradient)};
`
const blockMode = css`
  background: ${UserInterface.colors.clearBlue};
  margin: 37px;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    margin: 15px;
  }
  border-top-left-radius: ${({ background }) =>
    background.block === 'right' ? '168px' : '31px'};
  border-top-right-radius: ${({ background }) =>
    background.block === 'right' || background.block === 'all'
      ? '31px'
      : '168px'};
  border-bottom-right-radius: 31px;
  border-bottom-left-radius: 31px;
`

export const SectionStyled = styled.section`
  ${({ background }) => {
    if (background.color) {
      return colorMode
    } else if (background.block) {
      return blockMode
    }
  }}

  z-index: 10;
  ${({ paddingY }) =>
    paddingY ? `padding: ${paddingY} 0` : `padding: 3rem 0`};
`

const FlexStyled = styled(Flex)`
  ${({ reverseOnMobile }) =>
    reverseOnMobile &&
    css`
      @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
        flex-direction: column-reverse;
      }
    `};
`

/**
 * Component
 */
const Section = ({
  className,
  children,
  background = { color: 'extraLightGrey', block: '' }, // Color OR block / False color for hide background
  paddingY,
  alignItems = 'center',
  justifyContent,
  withGradient = false,
  reverseOnMobile = false,
}) => {
  return (
    <SectionStyled
      background={background}
      withGradient={withGradient}
      paddingY={paddingY}
      className={classNames('section', className, {
        'section--blue': background.color === 'blue',
      })}
    >
      <FlexStyled
        reverseOnMobile={reverseOnMobile}
        alignItems={alignItems}
        flexWrap="wrap"
        justifyContent={justifyContent}
      >
        {Children.map(children, child => {
          return React.cloneElement(child, { background: background.color })
        })}
      </FlexStyled>
    </SectionStyled>
  )
}

export default Section
