/* eslint-disable @typescript-eslint/no-explicit-any */
import { Svg, UserInterface } from '../..'
import { Keyframes, css, keyframes } from 'styled-components'
import classNames from 'classnames'

import React, { FC, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { IVideoPlayer } from './type'
import { useInViewport } from 'react-in-viewport'

interface IAnimation {
  animationVideoButton: Keyframes
}

/**
 * Style
 */

const VideoContainer = styled.div`
  height: auto;
  background-color: ${UserInterface.colors.clearBlue};
  padding 1.4rem 0 0 0;
`

const animationVideoButton = keyframes`
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`

const VideoImageWrapper = styled(props => <div {...props} />)`
  background-image: ${({ $backgroundImage, $hasBeenLaunched }) =>
    $hasBeenLaunched
      ? 'none'
      : `url('${process.env.GATSBY_CDN_HOST}/${$backgroundImage}.webp')`};

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${UserInterface.colors.clearBlue};
  width: 90%;
  margin: auto;
  position: relative;
  cursor: pointer;
  transition: opacity 0.5s ease; /* Add a transition effect for opacity */

  /* Maintain aspect ratio */
  &:before {
    content: '';
    display: block;
    padding-bottom: 56.25%; /* 16:9 aspect ratio, adjust as needed */
  }

  /* Styles applied when the videoImageWrapper--visible classname exists */
  &.videoImageWrapper--visible {
    opacity: 1;
    visibility: visible;
  }

  .playButton {
    width: 75px;
    height: 75px;

    svg {
      position: absolute;
      top: 6%;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 25px;
      width: 90%;
      height: auto;
    }

    @media (min-width: ${UserInterface.breakpoints.extraSmallMobile}) {
      width: 100px;
      height: 100px;
    }
  }

  .videoImageButton {
    left: calc(50% - 33px);
    top: calc(50% - 41px);
  }

  @media screen and (min-width: ${UserInterface.breakpoints.extraSmallMobile}) {
    .videoImageButton {
      left: calc(50% - 44px);
      top: calc(50% - 55px);
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    .videoImageButton {
      left: calc(50% - 22px);
      top: calc(50% - 45px);
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    .videoImageButton {
      left: calc(50% - 45px);
      top: calc(50% - 64px);
    }
  }

  &:hover .playButton {
    background-color: rgba(223, 223, 223, 0.86);
  }

  .wistia_responsive_wrapper {
    margin: auto;
  }

  /* Inner container to hold content */
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const PlayButtonWrapper = styled(props => <div {...props} />)`
  position: absolute;
`

const animationCall = css<IAnimation>`
  animation: ${animationVideoButton} 2.3s ease-in-out infinite;
`

const Button = styled.div<IAnimation>`
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.86);
  path {
    fill: ${UserInterface.colors.blue};
  }

  transition: 500ms ease;
  ${({ animationVideoButton }) => animationVideoButton && animationCall}
`

const VideoStyled = styled(props => <div {...props} />)`
  height: 100%;
  width: 100%;
  position: block;
  opacity: ${({ $isVideoVisible }) => ($isVideoVisible ? '1' : '0')};
  transition: opacity 1s ease-in-out;
  transition-delay: 0.5s;

  .wistia-click-to-play {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
  }
`

/**
 * Component
 */
const VideoWithImage: FC<IVideoPlayer> = ({
  image,
  videoId,
  popover = false,
  autoplay = false,
  displaySettings = true,
}) => {
  const [wistiaParameters, setWistiaParameters] = useState<
    string[] | undefined
  >(undefined)

  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [isVideoDisplayed, setIsVideoDisplayed] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(autoplay)
  const [hasBeenLaunched, setHasBeenLaunched] = useState(false)

  const myRef = useRef(null)
  const { inViewport } = useInViewport(myRef)

  useEffect(() => {
    if (isVideoPlaying && !popover) {
      setHasBeenLaunched(true)
    }
  }, [isVideoPlaying, popover])

  // Update parameters
  useEffect(() => {
    if (wistiaParameters === undefined) {
      const parametersConfiguration = []

      if (image) {
        parametersConfiguration.push('playButton=false')
      }

      if (popover) {
        parametersConfiguration.push(
          'popover=true',
          'popoverShowOnLoad=true',
          'popoverAnimateThumbnail=true'
        )
      }

      parametersConfiguration.push(
        'readyToPlay=true',
        `playbar${displaySettings}`,
        `volumeControl${displaySettings}`,
        `smallPlayButton${displaySettings}`,
        'settingsControl=false',
        `fullscreenButton=${displaySettings}`
      )

      setWistiaParameters(parametersConfiguration)
    }
  }, [wistiaParameters])

  // if parameters are updated, load script
  useEffect(() => {
    const existingScript = document.getElementById('wistia')

    if (
      inViewport &&
      (!isScriptLoaded || !existingScript) &&
      wistiaParameters
    ) {
      const wistiaScript = document.createElement('script')
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      wistiaScript.id = 'wistia'
      document.body.appendChild(wistiaScript)
      setIsScriptLoaded(true)
    }
  }, [inViewport, wistiaParameters, isScriptLoaded])

  // if script is loaded, add autoplay parameter
  useEffect(() => {
    if (!isScriptLoaded) return

    setWistiaParameters((previousParameters: string[] | undefined) =>
      previousParameters
        ? [...previousParameters, 'autoPlay=true']
        : ['autoPlay=true']
    )

    setIsVideoPlaying(true)
  }, [isScriptLoaded, isVideoDisplayed])

  if (!wistiaParameters) return null

  return (
    <VideoContainer ref={myRef}>
      <VideoImageWrapper
        $backgroundImage={image}
        $hasBeenLaunched={hasBeenLaunched}
        className={classNames(
          'videoImageWrapper',
          isVideoDisplayed && 'videoImageWrapper--visible'
        )}
        onClick={() => {
          setIsVideoPlaying(false)
          setIsVideoDisplayed(false)
          setTimeout(() => {
            setIsVideoDisplayed(true)
          }, 100)
        }}
      >
        {videoId && (
          <PlayButtonWrapper className="videoImageButton">
            <Button
              animationVideoButton={animationVideoButton}
              className="playButton"
            >
              <Svg
                colorSecondary="blue"
                src="components/video-button/simple-play"
                width="96px"
                color="white"
              />
            </Button>
          </PlayButtonWrapper>
        )}

        {isScriptLoaded && (
          <VideoStyled
            $isVideoVisible={isVideoPlaying && !popover}
            className={classNames(
              'wistia_responsive_wrapper',
              'wistia_embed',
              `wistia_async_${videoId}`,
              'videoFoam=true',
              ...(wistiaParameters ?? [])
            )}
          />
        )}
      </VideoImageWrapper>
    </VideoContainer>
  )
}

VideoWithImage.displayName = 'VideoPlayer'

export default VideoWithImage
