import React, { useState, useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import { v4 as uuid } from 'uuid'

const CountUp = ({ startNumber, endNumber }) => {
  const myRef = useRef(uuid())
  const { inViewport } = useInViewport(myRef)

  const [count, setCount] = useState(startNumber ?? 0)
  const [speed, setSpeed] = useState(undefined)

  const [speedUpOne, setSpeedUpOne] = useState(undefined)
  const [speedUpTwo, setSpeedUpTwo] = useState(undefined)

  const duration = 4
  const endNumberInt = parseInt(endNumber)

  useEffect(() => {
    if (speed === undefined) {
      if (count < 1000) {
        return setSpeed((1 / (100 / (duration * 60))) * 3)
      }
      return setSpeed((1 / (1000 / (duration * 60))) * 1000)
    }
  }, [count, speed])

  useEffect(() => {
    if (speed === undefined || !inViewport) {
      return
    }

    const timer = setInterval(() => {
      if (count < endNumberInt) {
        setCount(prevCount =>
          Math.ceil(prevCount + endNumberInt / (duration * 60))
        )
        // Case endNumberInt is less than 100
        if (count < 100 && endNumberInt < 100) {
          if (count / endNumberInt >= 0.85 && !speedUpOne) {
            setSpeed(speed * 25) // Slow down when reaching 85%
            setSpeedUpOne(true)
          }
          if (count / endNumberInt >= 0.95 && !speedUpTwo) {
            setSpeed(speed * 1.5) // Slow down when reaching 95%
            setSpeedUpTwo(true)
          }
        }
        // Case endNumberInt is less than 5000
        else if (count < 500 && endNumberInt < 500) {
          if (count / endNumberInt >= 0.85 && !speedUpOne) {
            setSpeed(speed * 7)
            setSpeedUpOne(true)
          }
          if (count / endNumberInt >= 0.9 && !speedUpTwo) {
            setSpeed(speed * 2.5)
            setSpeedUpTwo(true)
          }
        }
        // Case endNumberInt is less than 5000
        else if (count < 5000 && endNumberInt < 5000) {
          if (count / endNumberInt >= 0.9 && !speedUpOne) {
            setSpeed(speed * 10)
            setSpeedUpOne(true)
          }
          if (count / endNumberInt >= 0.95 && !speedUpTwo) {
            setSpeed(speed * 2)
            setSpeedUpTwo(true)
          }
        }
        // Case endNumberInt is higher than 5000
        else {
          if (count / endNumberInt >= 0.95 && !speedUpOne) {
            setSpeed(speed * 20)
            setSpeedUpOne(true)
          }
          if (count / endNumberInt >= 0.98 && !speedUpTwo) {
            setSpeed(speed * 2)
            setSpeedUpTwo(true)
          }
        }

        if (count > 1000 && count / endNumberInt >= 0.99) {
          setCount(Math.round(endNumberInt))
        }
      }
    }, speed)

    return () => {
      clearInterval(timer)
    }
  }, [
    count,
    endNumberInt,
    duration,
    speed,
    myRef,
    inViewport,
    speedUpOne,
    speedUpTwo,
  ])

  return <span ref={myRef}>{count.toLocaleString()}</span>
}

export default CountUp
