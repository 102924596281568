import React, { FC } from 'react'
import styled from 'styled-components'
import { IAuthorAvatar } from './type'

const AuthorAvatarStyled = styled(({ ...props }) => (
  <img alt={props.alt} {...props} />
))`
  width: ${({ big }) => (big ? '50px' : '30px')};
  height: ${({ big }) => (big ? '72px' : '43px')};
  vertical-align: middle;
  border-style: none;
`
const AuthorAvatar: FC<IAuthorAvatar> = ({ children, big, src, alt }) => {
  return (
    <AuthorAvatarStyled big={big} src={src} alt={alt ?? 'avatar-image'}>
      {children}
    </AuthorAvatarStyled>
  )
}

export default AuthorAvatar
