import React, { FC } from 'react'
import { Link, Svg } from '../index'
import styled from 'styled-components'
import { IBackButton } from './type'
import { EColor, EFontFamily } from 'src/styles/type'

const BackLink = styled(props => <Link {...props} />)`
  color: ${EColor.BLUE};
  text-decoration: none;
  margin-right: 14px;
  &:hover {
    color: ${EColor.MEDIUM_BLUE};
    text-decoration: underline;
    svg > .svg--color-primary {
      fill: ${EColor.MEDIUM_BLUE};
    }
  }
`
const ArrowSvg = styled(props => <Svg {...props} />)`
  svg > .svg--color-primary {
    fill: ${EColor.BLUE};
  }
`

const BackSpan = styled.span`
  display: block;
  font-family: ${EFontFamily.ROBOTO};
  font-size: 10px;
  margin-top: -2px;
`

const BackButton: FC<IBackButton> = ({ backLink, textContent }) => (
  <BackLink href={backLink}>
    <ArrowSvg src="components/arrow/long-arrow-left-light" height="28px" />
    <BackSpan>{textContent}</BackSpan>
  </BackLink>
)

export default BackButton
