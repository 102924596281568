import React, { FC } from 'react'
import styled from 'styled-components'
import { IPostCover } from './type'
import { UserInterface } from 'src/components'

const PostCoverWrap = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  margin-bottom: 19px;
  height: 150px;

  @media screen and (min-width: ${UserInterface.breakpoints.extraSmallMobile}) {
    height: 230px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    height: 300px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    height: 400px;
  }
`

const PostCoverStyled = styled.img`
  display: block;
  flex: 1 1 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`
const PostCover: FC<IPostCover> = ({ children, image, alt }) => {
  return (
    <PostCoverWrap>
      <PostCoverStyled src={image} alt={alt}>
        {children}
      </PostCoverStyled>
    </PostCoverWrap>
  )
}

export default PostCover
