// TO DO: Delete this file after all components are using Typescript
// See how to handle the theme usage in gatsby-browser.js and gatsby-ssr.js
const breakpoints = {
  extraSmallMobile: '500px',
  smallMobile: '900px',
  mobile: '1200px',
  largeTable: '1350px',
  table: '1500px',
  largeScreen: '2000px',
}

export default breakpoints
